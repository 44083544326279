import React, { useState, useRef, useEffect } from "react";
import { FileText, Loader2, CheckCircle2 } from "lucide-react";
import { UpFileChat } from "../Talktodata/UpFileChat.tsx";

const UploFile = () => {
  const [stage, setStage] = useState("fileSelection");
  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  let [nameSpaces, setNameSpaces] = useState(null);

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setIsUploading(true);
      await uploadFile(file, file.name);
      await new Promise((resolve) => setTimeout(resolve, 2000));
    }
  };

  const uploadFile = async (file, fileName) => {
    try {
      const token = sessionStorage.getItem("token");
      const formData = new FormData();
      formData.append("file_name", fileName);
      formData.append("file", file);
      console.log(formData, "Form DAta");

      const response = await fetch(
        `${process.env.REACT_APP_LOCALHOST_API_LINK}/upload_file/`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        const errorMessage = await response.text();
        console.error("Upload failed:", errorMessage);
        throw new Error("File upload failed");
      }

      const data = await response.json();
      console.log(data, "Data is received");
      console.log("File uploaded successfully!");
      if (data) {
        setIsUploading(false);
        getNameSpaces();
        alert("File Uploaded Successfully!!");
      }
    } catch (error) {
      console.error(error, "Error Loading!!");
    }
  };

  const handleFileSelect = (file) => {
    console.log(file, "File Name");
    setNameSpaces((nameSpaces = file));
    console.log(nameSpaces, "Namespaces");

    // setSelectedFile(fileName === selectedFile ? null : fileName);
    // console.log(selectedFile,"SelectedFile")
    setSelectedFile(file.fileName === selectedFile ? null : file.filename);
  };

  const handleFileUpload = async () => {
    if (!selectedFile) return;
    setIsUploading(true);

    const fileData = files.find((file) => file.filename === selectedFile);
    sessionStorage.setItem("selectedFile", JSON.stringify(fileData));

    await new Promise((resolve) => setTimeout(resolve, 2000));
    setStage("chat");
    setIsUploading(false);
  };

  const getNameSpaces = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const userEmail = sessionStorage.getItem("email");

      const response = await fetch(
        `${process.env.REACT_APP_LOCALHOST_API_LINK}/user_indexes/`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      console.log(data, "Data");
      setFiles(data);
      setIsLoading(false);
    } catch (error) {
      console.log(error, "Error Loading!!");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchFiles = async () => {
      await getNameSpaces();
    };

    fetchFiles();
  }, []);
  const changeMode = async () => {
    try {
      setIsUploading(true);
      // sessionStorage.setItem("selectedFile", JSON.stringify(fileData));
      await new Promise((resolve) => setTimeout(resolve, 2000));
      setStage("chat");
      setIsUploading(false);
    } catch (error) {
      return error;
    }
  };

  const FileCard = ({ filename, checked, onChange }) => (
    <div
      style={{
        width: "100%",
        maxWidth: "200px",
        aspectRatio: "1",
        border: `2px solid ${checked ? "#2563EB" : "#E5E7EB"}`,
        borderRadius: "12px",
        padding: "16px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: checked ? "#EFF6FF" : "#FFFFFF",
        position: "relative",
        transition: "all 0.3s ease",
        cursor: "pointer",
        boxShadow: checked
          ? "0 4px 12px rgba(37, 99, 235, 0.15)"
          : "0 2px 4px rgba(0, 0, 0, 0.05)",
      }}
      onClick={onChange}
      onMouseEnter={(e) => {
        e.currentTarget.style.transform = "translateY(-2px)";
        e.currentTarget.style.boxShadow = "0 6px 16px rgba(0, 0, 0, 0.1)";
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.transform = "translateY(0)";
        e.currentTarget.style.boxShadow = checked
          ? "0 4px 12px rgba(37, 99, 235, 0.15)"
          : "0 2px 4px rgba(0, 0, 0, 0.05)";
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "12px",
          right: "12px",
          width: "24px",
          height: "24px",
          borderRadius: "50%",
          border: `2px solid ${checked ? "#2563EB" : "#D1D5DB"}`,
          backgroundColor: checked ? "#2563EB" : "white",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          transition: "all 0.2s ease",
        }}
      >
        {checked && <CheckCircle2 size={14} color="white" />}
      </div>

      <div
        style={{
          width: "80px",
          height: "80px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "20px",
          marginBottom: "16px",
          transition: "all 0.2s ease",
        }}
      >
        <FileText size={48} color={checked ? "#2563EB" : "#6B7280"} />
      </div>

      <div
        style={{
          textAlign: "center",
          marginBottom: "8px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxWidth: "100%",
        }}
      >
        <div
          style={{
            fontWeight: 600,
            color: checked ? "#2563EB" : "#374151",
            fontSize: "16px",
            marginBottom: "4px",
            transition: "all 0.2s ease",
          }}
        >
          {filename}
        </div>
      </div>

      <div
        style={{
          fontSize: "12px",
          color: "#6B7280",
          marginTop: "auto",
        }}
      >
        Last modified: Today
      </div>
    </div>
  );

  if (stage === "fileSelection") {
    return (
      <div
        style={{
          minHeight: "100vh",
          backgroundColor: "#F9FAFB",
          padding: "32px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            maxWidth: "900px",
            margin: "0 auto",
          }}
        >
          <div className="d-flex justify-content-center">
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
            />

            <button
              className="btn btn-primary gap-2"
              onClick={handleButtonClick}
            >
              Create Space
              <i className="fa fa-upload" style={{ marginLeft: "10px" }}></i>
            </button>
          </div>

          {isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "400px",
              }}
            >
              <Loader2
                style={{
                  animation: "spin 1s linear infinite",
                }}
                size={48}
              />
            </div>
          ) : (
            <>
              <h2
                style={{
                  fontSize: "24px",
                  fontWeight: "bold",
                  textAlign: "center",
                  marginBottom: "32px",
                  color: "#1F2937",
                  marginTop: "30px",
                }}
              >
                Select Existing Space
              </h2>

              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gap: "24px",
                  height: "400px",
                  overflowY: "scroll",
                  marginBottom: "32px",
                  padding: "4px",
                  scrollbarWidth: "none",
                  msOverflowStyle: "none",
                }}
              >
                {files.map((file) => (
                  <FileCard
                    key={file.index_name}
                    filename={file.filename}
                    checked={selectedFile === file.filename}
                    onChange={() => handleFileSelect(file)}
                  />
                ))}
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <button
                  disabled={!selectedFile || isUploading}
                  onClick={changeMode}
                  style={{
                    padding: "12px 32px",
                    borderRadius: "8px",
                    border: "none",
                    backgroundColor: isUploading
                      ? "#93C5FD"
                      : selectedFile
                      ? "#2563EB"
                      : "#E5E7EB",
                    color: selectedFile || isUploading ? "#FFFFFF" : "#9CA3AF",
                    cursor:
                      !selectedFile || isUploading ? "not-allowed" : "pointer",
                    display: "flex",
                    alignItems: "center",
                    gap: "8px",
                    fontWeight: "500",
                    transition: "all 0.2s ease",
                    boxShadow:
                      selectedFile && !isUploading
                        ? "0 4px 12px rgba(37, 99, 235, 0.2)"
                        : "none",
                  }}
                >
                  {isUploading ? (
                    <>
                      <Loader2
                        style={{
                          animation: "spin 1s linear infinite",
                        }}
                        size={20}
                      />
                      <span>Loading...</span>
                    </>
                  ) : (
                    <>
                      <CheckCircle2 size={20} />
                      <span>Proceed</span>
                    </>
                  )}
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }

  return (
    <>
      <UpFileChat data={nameSpaces} />
    </>
  );
};

export default UploFile;
