import React from "react";
import Access from "../../assets/images/accessed.png";
import "./AccessTab.css";
export const AccessTab = () => {
  return (
    <div className="col-md-12 p-2">
      <div className="accesstopNav">
        <img src={Access} alt="" className="accessimage" />
      </div>
    </div>
  );
};
