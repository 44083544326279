import React, { useState, useContext, useEffect } from "react";

import loginimage from "../../assets/images/10173532.jpg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BeforeUnloadContext } from "../../App";

export const DumLogin = () => {
  const [data, setData] = useState({});
  let [error, setError] = useState("");
  useEffect(() => {
    sessionStorage.clear();
  }, []);
  const showToastMessage = () => {
    toast.success("Login Successfull !!", {
      position: "top-right",
    });
  };
  const showToastMessage1 = () => {
    toast.error(error, {
      position: "top-right",
    });
  };

  const { setIsPerformingAction } = useContext(BeforeUnloadContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsPerformingAction(true);

    const response = await fetch(
      `${process.env.REACT_APP_LOCALHOST_API_LINK}/authenticate`,
      {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const result = await response.json();
    console.log(result, "ersult");
    console.log(response.status, "status received");

    if (response.status === 200) {
      const token = result.AuthenticationInfo.jwt_token;
      const userRole = result.AuthenticationInfo.UserRole;
      const userName = result.AuthenticationInfo.UserName;
      const userId = result.AuthenticationInfo.email;
      console.log(userRole, "USRROLE");
      if (userRole === "Ally-Admin") {
        showToastMessage();
        sessionStorage.setItem("token", token);
        sessionStorage.setItem("email", userId);
        sessionStorage.setItem("userRole", userRole);
        sessionStorage.setItem("userName", userName);

        setTimeout(() => {
          window.location.href = "/dashboard/homepage";
        }, 5000);

        const message = result.message;
        if (token) {
          setTimeout(() => {
            window.location.href = "/upfile";
          }, 2000);
        } else {
          setError((error = message));
          showToastMessage1();
          console.log(error, "Error");
        }
      } else {
        setError((error = "Only Admin can Login with this!"));
        showToastMessage1();
      }
    } else if (response.status === 404) {
      setError((error = result.detail));
      showToastMessage1(); // Show error toast message
    } else if (response.status === 500) {
      setError((error = result.detail));
      showToastMessage1(); // Show error toast message
    } else if (response.status === 401) {
      setError((error = result.detail));
      showToastMessage1(); // Show error toast message
    } else {
      setError(result.message || "An error occurred.");
      showToastMessage1();
      setIsPerformingAction(false);
    }
  };

  return (
    <div id="login">
      <div className="login-container">
        <div className="login-form">
          <div className="login-form-inner">
            <div className="logo">
              <svg
                height="512"
                viewBox="0 0 192 192"
                width="512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="m155.109 74.028a4 4 0 0 0 -3.48-2.028h-52.4l8.785-67.123a4.023 4.023 0 0 0 -7.373-2.614l-63.724 111.642a4 4 0 0 0 3.407 6.095h51.617l-6.962 67.224a4.024 4.024 0 0 0 7.411 2.461l62.671-111.63a4 4 0 0 0 .048-4.027z" />
              </svg>
            </div>
            <h1 className="text-3xl">Login</h1>
            <p className="body-text">
              See your growth and get consulting support!
            </p>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <div className="login-form-group">
                <label htmlFor="email">
                  Email <span className="required-star">*</span>
                </label>
                <input
                  type="text"
                  placeholder="email@website.com"
                  id="email"
                  onChange={(e) => setData({ ...data, email: e.target.value })}
                />
              </div>
              <div className="login-form-group">
                <label htmlFor="pwd">
                  Password <span className="required-star">*</span>
                </label>
                <input
                  autoComplete="off"
                  type="password"
                  placeholder="Minimum 8 characters"
                  id="pwd"
                  onChange={(e) =>
                    setData({ ...data, password: e.target.value })
                  }
                />
              </div>

              <div className="login-form-group single-row">
                <div className="custom-check">
                  <input
                    autoComplete="off"
                    type="checkbox"
                    defaultChecked
                    id="remember"
                  />
                  <label htmlFor="remember">Remember me</label>
                </div>

                <a href="#" className="link forgot-link">
                  Forgot Password ?
                </a>
              </div>

              <a
                href="#"
                className="rounded-button login-cta"
                onClick={handleSubmit}
              >
                Login
              </a>
            </form>
          </div>
        </div>
        <div className="onboarding">
          <div className="swiper-container">
            <div className="swiper-wrapper">
              <div className="swiper-slide color-1">
                <div className="slide-image">
                  <img src={loginimage} loading="lazy" alt="Startup Launch" />
                </div>
              </div>
            </div>
            <div className="swiper-pagination"></div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};
