import React, { useEffect, useState } from "react";
import "./Dash.css";
import "./Dashboard.css";
import Question from "../../assets/images/latest/chat.png";
import Mark from "../../assets/images/latest/question-mark.png";
import CheckMark from "../../assets/images/latest/check.png";
import Cancel from "../../assets/images/latest/canc.png";
import Chart from "./Chart.tsx";
import Loader from "../../assets/images/LoaderLoading.gif";
import Chk from "../../assets/images/chk.png";
import { PieChart } from "./PieChart.tsx";
import { formatDistanceToNow } from "date-fns";
import chatimg from "../../assets/images/chatimg.png";

import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";

import DOMPurify from "dompurify";
import { Spinner } from "react-bootstrap";
import Server from "../../assets/images/server.png";

import ApplicationServer from "../../assets/images/applicationserver.png";
import Correct from "../../assets/images/correct.png";
import CommunicationServer from "../../assets/images/commuimage.png";
import Payments from "../../assets/images/paymentsimages.png";
import MobileApps from "../../assets/images/phoneimage.png";
import Documentation from "../../assets/images/documentsimages.png";
import Integration1 from "../../assets/images/integrationimage.png";
import Integration2 from "../../assets/images/solutions.png";
import AI from "../../assets/images/aiimage.png";

// import Chart from 'react-apexcharts';
import styled from "styled-components";
import ZendeskBarChart from "./ZendeskBarChart.tsx";
import ZendeskAverageBarChart from "./ZendeskAverageBarChart.tsx";
import ZendeskTickets from "./ZendeskTickets.tsx";
import Refresh from "../../assets/images/refresh.png";
import { Steps, Hints } from "intro.js-react";
import "intro.js/introjs.css";
import ZendeskStackBarChart from "./ZendeskStackBarChart.tsx";
import RawHtmlViewer from "./RawhtmlViewer.tsx";
import JiraAnalytics from "../Chatpage/JiraAnalytics.jsx";
import HeatMap from "../Heatmaps/Heatmap.tsx";
import TicketsHeatMap from "../Heatmaps/TicketsHeatMap.tsx";
import Joyride, { CallBackProps, STATUS, Step } from "react-joyride";
import { Chat } from "../Chatpage/Chat.tsx";

interface IMessage {
  forEach(arg0: (chat: any) => void): unknown;
  id: string;
  name: string;
  type: string;
  output: string;
  createdAt: string;
  relevantLinks: string[];
}

function Dash() {
  const [uniqueChatsLast15Days, setUniqueChatsLast15Days] = useState({});
  let [inputValue, setInputValue] = useState("");
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate("/dashboard/chat", { state: { inputValue } });
  };
  const [lastUpdated1, setLastUpdated1] = useState<string>("");
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);

  const showModaal = () => {
    setShowModal(true);
  };

  const getISTTime = (): string => {
    const now = new Date();
    const options: Intl.DateTimeFormatOptions = {
      timeZone: "Asia/Kolkata",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    };
    return now.toLocaleTimeString("en-US", options);
  };
  const [state, setState] = React.useState({
    run: false,
    steps: [
      {
        content: (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "200px",
              padding: "20px",
              backgroundColor: "#ffffff",
              borderRadius: "8px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              textAlign: "center",
              position: "relative",
              fontFamily: "Poppins",
              marginTop: "10px",
            }}
          >
            <div
              style={{
                position: "absolute",

                left: "10px",
                right: "10px",
                bottom: "10px",
                borderRadius: "8px",
                background:
                  "linear-gradient(to bottom right, #e9ecef, #ffffff)",
                zIndex: -1,
                fontFamily: "Poppins",
              }}
            ></div>
            <div>
              <i
                className="bx bxs-bot bx-lg"
                style={{
                  color: "#6c757d",
                  marginBottom: "12px",
                }}
              ></i>
              <h2
                style={{
                  fontSize: "24px",
                  fontWeight: "700",
                  marginBottom: "12px",
                  color: "#343a40",
                  letterSpacing: "0.5px",
                  fontFamily: "Poppins",
                }}
              >
                Welcome to Ally AI Assistant!
              </h2>
              <p
                style={{
                  fontSize: "14px",
                  margin: "0",
                  color: "#6c757d",
                  lineHeight: "1.6",
                  fontFamily: "Poppins",
                  padding: "2px",
                }}
              >
                Discover insights tailored to your business and unlock new
                opportunities. Let our AI guide you through a personalized
                journey of growth and understanding.
              </p>
            </div>
          </div>
        ),
        locale: { skip: <strong aria-label="skip">Skip</strong> },
        placement: "center",
        target: "body",
      },

      {
        content: (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "300px",
              padding: "20px",
              backgroundColor: "#ffffff",
              borderRadius: "8px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              textAlign: "center",
              position: "relative",
              fontFamily: "Poppins",
              marginTop: "10px",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "10px",
                left: "10px",
                right: "10px",
                bottom: "10px",
                borderRadius: "8px",
                background:
                  "linear-gradient(to bottom right, #e9ecef, #ffffff)",
                zIndex: -1,
                fontFamily: "Poppins",
              }}
            ></div>
            <div>
              <i
                className="bx bxs-cog bx-lg"
                style={{
                  fontSize: "48px",
                  color: "#6c757d",
                  marginBottom: "12px",
                }}
              ></i>
              <h2
                style={{
                  fontSize: "24px",
                  fontWeight: "700",
                  marginBottom: "12px",
                  color: "#343a40",
                  letterSpacing: "0.5px",
                  fontFamily: "Poppins",
                }}
              >
                System Status Overview
              </h2>
              <p
                style={{
                  fontSize: "14px",
                  margin: "0",
                  color: "#6c757d",
                  lineHeight: "1.6",
                  fontFamily: "Poppins",
                }}
              >
                View the status of various components of our system. Click on
                each item to see more details about its uptime and current
                status.
              </p>
            </div>
          </div>
        ),
        locale: { skip: <strong aria-label="skip">Skip</strong> },
        placement: "right",
        target: ".statusdiv",
      },

      {
        content: (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "300px",
              padding: "20px",
              backgroundColor: "#ffffff",
              borderRadius: "8px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              textAlign: "center",
              position: "relative",
              fontFamily: "Poppins",
              marginTop: "10px",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "10px",
                left: "10px",
                right: "10px",
                bottom: "10px",
                borderRadius: "8px",
                background:
                  "linear-gradient(to bottom right, #e9ecef, #ffffff)",
                zIndex: -1,
                fontFamily: "Poppins",
              }}
            ></div>
            <div>
              <i
                className="bx bxs-chart bx-lg"
                style={{
                  fontSize: "48px",
                  color: "#6c757d",
                  marginBottom: "12px",
                }}
              ></i>
              <h2
                style={{
                  fontSize: "24px",
                  fontWeight: "700",
                  marginBottom: "12px",
                  color: "#343a40",
                  letterSpacing: "0.5px",
                  fontFamily: "Poppins",
                }}
              >
                Ticket Status Overview
              </h2>
              <p
                style={{
                  fontSize: "14px",
                  margin: "0",
                  color: "#6c757d",
                  lineHeight: "1.6",
                  fontFamily: "Poppins",
                }}
              >
                Explore the heatmap to visualize the status of your tickets over
                various time frames. Use the dropdown menu to select a different
                time frame and view how ticket statuses vary across different
                periods.
              </p>
              <p
                style={{
                  fontSize: "14px",
                  marginTop: "12px",
                  color: "#6c757d",
                  lineHeight: "1.6",
                  fontFamily: "Poppins",
                }}
              >
                The heatmap will display a color-coded representation of ticket
                statuses, helping you identify trends and potential areas
                needing attention.
              </p>
            </div>
          </div>
        ),
        locale: { skip: <strong aria-label="skip">Skip</strong> },
        placement: "left",
        target: ".ticketheatmap",
      },
    ],
  });
  const handleClickStart = (event) => {
    event.preventDefault();
    setState((prevState) => ({
      ...prevState,
      run: true,
    }));
  };
  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status, type } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setState((prevState) => ({
        ...prevState,
        run: false,
      }));
    }

    console.log(type, data);
  };
  const handleRefresh1 = (): void => {
    const currentTime = getISTTime();
    setLastUpdated1(currentTime);
    sessionStorage.setItem("lastUpdated", currentTime);
  };
  useEffect(() => {
    const storedTime = sessionStorage.getItem("lastUpdated");
    if (storedTime) {
      setLastUpdated1(storedTime);
    } else {
      setLastUpdated1(getISTTime());
    }
  }, []);

  const [likeData, setLikeData] = useState();
  const [loader, setLoader] = useState(false);
  let [selectedPeriod, setSelectedPeriod] = useState(30);
  const [chats, setChats] = useState<IMessage[]>([]);

  const [activePersona, setActivePersona] = useState(
    sessionStorage.getItem("userRole")
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 5000); // 5 seconds

    return () => clearTimeout(timer);
  }, []);
  useEffect(() => {
    getChats();
  }, []);
  const getChats = async () => {
    // setLoading(true);
    try {
      const token = sessionStorage.getItem("token");
      const userEmail = sessionStorage.getItem("email");

      const response = await fetch(
        `${process.env.REACT_APP_LOCALHOST_API_LINK}/chat_history/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      console.log(data, "data.MetaData");
      setChats(data.MetaData);
      const currentDate = new Date();
      const uniqueChatsLast15Days = {};

      Object.entries(data.MetaData).forEach(([chatId, chats]) => {
        chats.forEach((chat) => {
          const chatDate = chat.resp_time.split("T")[0];
          const user = chat.user;
          const words = chat.response.split(" ");
          const firstWords =
            user === userEmail ? words.slice(0, 14).join(" ") : null;

          const diffInDays = Math.floor(
            (currentDate - new Date(chatDate)) / (1000 * 60 * 60 * 24)
          );
          if (diffInDays <= 15 && firstWords && words.length >= 0) {
            uniqueChatsLast15Days[chatId] = {
              firstWords,
              createdAt: chat.resp_time,
            };
          }
        });
      });

      const sortChatsByDate = (obj) => {
        return Object.fromEntries(
          Object.entries(obj).sort((a, b) => {
            const timestampA = new Date(a[1].createdAt).getTime();
            const timestampB = new Date(b[1].createdAt).getTime();
            return timestampA - timestampB; // Sort in descending order
          })
        );
      };

      const sortedChats = sortChatsByDate(uniqueChatsLast15Days);
      setUniqueChatsLast15Days(sortedChats);
      setLoading(false);
      console.warn(sortedChats, "Last 15 Days");
    } catch (error) {
      console.log(error, "Error Loading!!");
    }
  };

  const [lastUpdated, setLastUpdated] = useState(new Date());
  const [initialStep, setInitialStep] = useState(0);

  const StatusContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    padding: 20px;
    border-radius: 10px;
    background: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
  `;

  const StatusItem = styled.div`
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  `;

  const Icon = styled.div`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 10px;
    background: ${(props) =>
      props.status === "green" ? "#28a745" : "#dc3545"};
  `;

  const Label = styled.span`
    font-size: 1.2em;
  `;
  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      setLastUpdated(new Date());
    }, 60000); // update every minute

    return () => clearInterval(interval);
  }, []);
  const handlePeriodChange = (event) => {
    setSelectedPeriod((selectedPeriod = Number(event.target.value)));
    console.log(selectedPeriod, "SELEC");
  };
  const handleRefresh = () => {
    setLastUpdated(new Date());
  };
  const getTimeDifference = (lastUpdated) => {
    const now = new Date();
    const diffInMs = now - lastUpdated;
    const diffInMinutes = Math.floor(diffInMs / 60000);
    return diffInMinutes === 0 ? "Just now" : `${diffInMinutes} min ago`;
  };

  const getData = async () => {
    setLoader(true);
    try {
      const token = sessionStorage.getItem("token");
      const response = await fetch(
        `${process.env.REACT_APP_LOCALHOST_API_LINK}/user-stats/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      console.log(data, "Data is Loaded");
      setLikeData(data);

      // Update data array with fetched data
      const updatedData = [
        {
          name: "Total Questions",
          number: data?.overall_total_queries,
          image: Question,
        },
        {
          name: "Positive Response",
          number: data?.like_count || 0,
          image: CheckMark,
        },
        {
          name: "Negative Response",
          number: data?.dislike_count || 0,
          image: Mark,
        },
        { name: "Wrong Answers", number: 0, image: Cancel },
      ];
      setData(updatedData);
      setLoader(false);
    } catch (error) {
      console.log(error, "Error Loading!!");
    }
  };

  const [data, setData] = useState([
    {
      name: "Total Questions",
      number: likeData?.overall_total_queries,
      image: Question,
    },
    {
      name: "Positive Repsonse",
      number: likeData?.like_count || 0,
      image: CheckMark,
    },
    {
      name: "Negative Repsonse",
      number: likeData?.dislike_count || 0,
      image: Mark,
    },
    { name: "Wrong Answers", number: 44, image: Cancel },
  ]);
  const [isQueryChecked, setIsQueryChecked] = useState(false);
  const [isProductChecked, setIsProductChecked] = useState(false);

  const handleQueryChange = () => {
    setIsQueryChecked(!isQueryChecked);
    setIsProductChecked(false); // Disable the other checkbox
  };

  const handleProductChange = () => {
    setIsProductChecked(!isProductChecked);
    setIsQueryChecked(false); // Disable the other checkbox
  };
  const [showDropdown, setShowDropdown] = useState(false);
  const getFormattedTime = (dateString) => {
    const serverDate = new Date(dateString);

    // Convert server date (assumed to be in UTC) to local time
    const localDate = new Date(
      serverDate.getTime() - serverDate.getTimezoneOffset() * 60000
    );

    console.log("Local Date:", localDate);

    return formatDistanceToNow(localDate, { addSuffix: true });
  };
  const handleFocus = () => {
    setShowDropdown(true);
  };
  const sortedChats = Object.entries(uniqueChatsLast15Days)
    .sort((a, b) => new Date(b[1].createdAt) - new Date(a[1].createdAt))
    .slice(0, 2);

  const handleBlur = () => {
    setTimeout(() => setShowDropdown(false), 100);
  };

  return (
    <div
      className="container-fluid p-1"
      style={{
        height: "100vh",
        overflow: "scroll",
        msOverflowStyle: "none",
        scrollbarWidth: "none",
      }}
    >
      {/* <span onClick={showModaal}>
        <img
          src={require("../../assets/images/chatss.png")}
          alt=""
          style={{
            position: "absolute",
            cursor: "pointer",
            width: "52px",
            height: "52px",
            bottom: "5%",
            zIndex: "1000",
            right: "3%",
          }}
        />
      </span> */}
      <Joyride
        callback={handleJoyrideCallback}
        continuous
        run={state.run}
        scrollToFirstStep
        showProgress
        showSkipButton
        steps={state.steps}
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
      />
      <div className="row" style={{ marginBottom: "15px" }}>
        <div className="col-md-12 d-flex flex-column flex-md-row justify-content-between align-items-center">
          <h4
            className="mb-md-0"
            style={{ fontWeight: "300", color: "#022E57" }}
          >
            Dashboard
          </h4>
          <div className="inputfieldcontainer2 mb-2  mt-2 mb-md-0">
            <i className="bx bx-search search-icon1"></i>
            <form onSubmit={handleSubmit} className="newinputfield1">
              <div className="input-container21">
                <input
                  type="text"
                  className={`newinputfield1 ${inputValue ? "has-text" : ""}`}
                  placeholder="How may I assist you today?"
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  onChange={(e) => {
                    setInputValue(e.target.value);
                  }}
                  value={inputValue}
                />
                <span
                  className={`submit-icon ${inputValue ? "show-icon" : ""}`}
                  onClick={handleSubmit}
                >
                  <i
                    className="bx bx-up-arrow-alt bx-sm"
                    style={{ color: "gray" }}
                  ></i>
                </span>
              </div>
            </form>
            {showDropdown && (
              <>
                <div className="dropdown11">
                  <div className="mb-4">
                    <span
                      style={{
                        fontSize: "12px",
                        color: "gray",
                        marginTop: "10px",
                        display: "inline-flex",
                        alignItems: "center",
                        marginRight: "20px",
                        fontWeight:600

                      }}
                    >
                      <input
                        type="checkbox"
                        checked={isQueryChecked}
                        onChange={handleQueryChange}
                        disabled={isProductChecked} // Disable if Product is checked
                        style={{
                          marginRight: "5px",
                          marginTop: "px",
                        }}
                      />
                      Query Data
                    </span>
                    <span
                      style={{
                        fontSize: "12px",
                        color: "gray",
                        marginTop: "5px",
                        display: "inline-flex",
                        alignItems: "center",
                        fontWeight:600
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={isProductChecked}
                        onChange={handleProductChange}
                        disabled={isQueryChecked}
                        style={{
                          marginRight: "5px",
                          marginTop: "px",
                        }}
                      />
                      Product Data
                    </span>
                  </div>
                  <h6
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "12px",
                      color: "gray",
                      fontWeight: "600",
                    }}
                  >
                    Your Recent Chats
                  </h6>

                  <div className="d-flex">
                    <div className="chatsdiv d-flex p-1 justify-content-between">
                      <div className="row col-12">
                        {chats && chats.length > 0 ? (
                          <>
                            <div className="d-flex justify-content-center align-items-center">
                              <img
                                src={Loader}
                                alt=""
                                style={{ height: "20px", width: "20px" }}
                              />
                            </div>
                          </>
                        ) : (
                          <>
                            {sortedChats.map(([chatId, chat], index) => (
                              <div
                                key={chatId || index} // Use chatId if unique; otherwise, use the index
                                className="col-md-5 m-1 p-2 chatshowcase"
                                style={{
                                  border: "1px solid #F6F1F0",
                                  borderRadius: "20px",
                                  textAlign: "justify",
                                }}
                                onClick={() => {
                                  console.log("Chat clicked:", chat);
                                  setInputValue(chat.firstWords); // Set inputValue to chat.firstWords
                                }}
                              >
                                <span>
                                  <i
                                    className="bx bx-message-dots bx-sm"
                                    style={{
                                      color: "#1c9aaa",
                                      marginTop: "10px",
                                      marginLeft: "5px",
                                    }}
                                  ></i>
                                  <h6
                                    style={{
                                      fontSize: "14px",
                                      color: "gray",
                                      marginTop: "10px",
                                    }}
                                  >
                                    {chat.firstWords}
                                  </h6>
                                  <h6
                                    style={{
                                      fontSize: "10px",
                                      color: "gray",
                                      marginTop: "10px",
                                    }}
                                  >
                                    {getFormattedTime(chat.createdAt)}
                                  </h6>
                                </span>
                              </div>
                            ))}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <button
            className="btn btn-secondary"
            style={{
              backgroundColor: "#022E57",
              borderRadius: "20px",
              border: "none",
              color: "white",
              width: "100%",
              maxWidth: "124px",
            }}
            onClick={handleClickStart}
          >
            Take a Tour
          </button>
        </div>
      </div>

      {activePersona !== "Ally-User" && (
        <>
          <div className="row mt-4 p-3 ">
            <div className="col-md-6 cards statusdiv">
              <div className="d-flex justify-content-end card-bodys">
                <img
                  src={Refresh}
                  alt=""
                  style={{ width: "20px", height: "20px", cursor: "pointer" }}
                  onClick={handleRefresh}
                />
              </div>
              <div className="d-flex justify-content-center mt-1">
                <div>
                  <img
                    src={Server}
                    alt=""
                    className="img_responsvie"
                    style={{ width: "70px", height: "70px" }}
                  />
                </div>
              </div>
              <div className="mt-4">
                <h6
                  style={{
                    fontWeight: "500",
                    textAlign: "center",
                    color: "gray",
                  }}
                >
                  Gingr Status
                </h6>
              </div>
              <div className="">
                <h4 style={{ textAlign: "center", fontWeight: "500" }}>
                  All Systems are up and Running{" "}
                </h4>
                <h6
                  style={{
                    textAlign: "center",
                    fontWeight: "500",
                    color: "gray",
                  }}
                >
                  Last updated today, {lastUpdated1} IST IST{" "}
                </h6>
              </div>
              <div className="row">
                <div className="col-md-6" style={{ cursor: "pointer" }}>
                  <div className="p-2 m-3 fullclass">
                    <div className="fullclass1">
                      <img
                        src={ApplicationServer}
                        alt=""
                        style={{
                          width: "40px",
                          height: "40px",
                          marginTop: "5px",
                        }}
                      />
                    </div>
                    <div className="fullclass2">
                      <span className="">Application Server</span> <br />
                      <span className="description">
                        99.93 % uptime for the last 30 days
                      </span>
                    </div>
                    <div>
                      <img
                        src={Correct}
                        alt=""
                        style={{
                          width: "25px",
                          height: "25px",
                          marginLeft: "18px",
                        }}
                      />{" "}
                      <br />
                      <span
                        style={{
                          color: "gray",
                          fontWeight: "500",
                          fontSize: "14px",
                        }}
                      >
                        Available
                      </span>
                    </div>
                  </div>

                  <div className="p-2 m-3 fullclass">
                    <div className="fullclass1">
                      <img
                        src={CommunicationServer}
                        alt=""
                        style={{
                          width: "40px",
                          height: "40px",
                          marginTop: "5px",
                        }}
                      />
                    </div>
                    <div className="fullclass2">
                      <span className="">Communication Server</span> <br />
                      <span className="description">
                        99.93 % uptime for the last 30 days
                      </span>
                    </div>
                    <div>
                      <img
                        src={Correct}
                        alt=""
                        style={{
                          width: "25px",
                          height: "25px",
                          marginLeft: "18px",
                        }}
                      />{" "}
                      <br />
                      <span
                        style={{
                          color: "gray",
                          fontWeight: "500",
                          fontSize: "14px",
                        }}
                      >
                        Available
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6" style={{ cursor: "pointer" }}>
                  <div className="p-2 m-3 fullclass">
                    <div className="fullclass1">
                      <img
                        src={Payments}
                        alt=""
                        style={{
                          width: "40px",
                          height: "40px",
                          marginTop: "5px",
                        }}
                      />
                    </div>
                    <div className="fullclass2">
                      <span className="">Payments Server</span> <br />
                      <span className="description">
                        92.93 % uptime for the last 30 days
                      </span>
                    </div>
                    <div>
                      <img
                        src={Correct}
                        alt=""
                        style={{
                          width: "25px",
                          height: "25px",
                          marginLeft: "18px",
                        }}
                      />{" "}
                      <br />
                      <span
                        style={{
                          color: "gray",
                          fontWeight: "500",
                          fontSize: "14px",
                        }}
                      >
                        Available
                      </span>
                    </div>
                  </div>
                  <div className="p-2 m-3 fullclass">
                    <div className="fullclass1">
                      <img
                        src={MobileApps}
                        alt=""
                        style={{
                          width: "40px",
                          height: "40px",
                          marginTop: "5px",
                        }}
                      />
                    </div>
                    <div className="fullclass2">
                      <span className="">Mobile Applications</span> <br />
                      <span className="description">
                        99.93 % uptime for the last 30 days
                      </span>
                    </div>
                    <div>
                      <img
                        src={Correct}
                        alt=""
                        style={{
                          width: "25px",
                          height: "25px",
                          marginLeft: "18px",
                        }}
                      />{" "}
                      <br />
                      <span
                        style={{
                          color: "gray",
                          fontWeight: "500",
                          fontSize: "14px",
                        }}
                      >
                        Available
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 ticketheatmap">
              {sessionStorage.getItem("userRole") != "Ally-Admin" ? (
                <>
                  <TicketsHeatMap />
                </>
              ) : (
                <>
                  {" "}
                  <ZendeskTickets />
                </>
              )}
            </div>
            {/* <div className="col-md-6">
              <div
                className="p-1 m-1"
                style={{ border: "1px solid #F5F5F5", borderRadius: "10px" }}
              >
                <div className="d-flex justify-content-end">
                  <img
                    src={Refresh}
                    alt=""
                    style={{ width: "20px", height: "20px", cursor: "pointer" }}
                    onClick={handleRefresh1}
                  />
                </div>
                <div className="d-flex justify-content-center mt-1">
                  <div>
                    <img
                      src={Server}
                      alt=""
                      className="img_responsvie"
                      style={{ width: "70px", height: "70px" }}
                    />
                  </div>
                </div>
                <div className="mt-4">
                  <h6
                    style={{
                      fontWeight: "500",
                      textAlign: "center",
                      color: "gray",
                    }}
                  >
                    Ally Status
                  </h6>
                </div>
                <div className="">
                  <h4 style={{ textAlign: "center", fontWeight: "500" }}>
                    All Systems are up and Running{" "}
                  </h4>
                  <h6
                    style={{
                      textAlign: "center",
                      fontWeight: "500",
                      color: "gray",
                    }}
                  >
                    Last updated today, {lastUpdated1} IST{" "}
                  </h6>
                </div>
                <div className="row">
                  <div className="col-md-6" style={{ cursor: "pointer" }}>
                    <div className="p-2 m-3 fullclass">
                      <div className="fullclass1">
                        <img
                          src={Documentation}
                          alt=""
                          style={{
                            width: "40px",
                            height: "40px",
                            marginTop: "5px",
                          }}
                        />
                      </div>
                      <div className="fullclass2">
                        <span className="">Gingr Documentaion</span> <br />
                        <span className="description">
                          99.93 % uptime for the last 30 days
                        </span>
                      </div>
                      <div>
                        <img
                          src={Correct}
                          alt=""
                          style={{
                            width: "25px",
                            height: "25px",
                            marginLeft: "18px",
                          }}
                        />{" "}
                        <br />
                        <span
                          style={{
                            color: "gray",
                            fontWeight: "500",
                            fontSize: "14px",
                          }}
                        >
                          Available
                        </span>
                      </div>
                    </div>

                    <div className="p-2 m-3 fullclass">
                      <div className="fullclass1">
                        <img
                          src={Integration1}
                          alt=""
                          style={{
                            width: "40px",
                            height: "40px",
                            marginTop: "5px",
                          }}
                        />
                      </div>
                      <div className="fullclass2">
                        <span className="">Jira Integration</span> <br />
                        <span className="description">
                          95.93 % uptime for the last 30 days
                        </span>
                      </div>
                      <div>
                        <img
                          src={Correct}
                          alt=""
                          style={{
                            width: "25px",
                            height: "25px",
                            marginLeft: "18px",
                          }}
                        />{" "}
                        <br />
                        <span
                          style={{
                            color: "gray",
                            fontWeight: "500",
                            fontSize: "14px",
                          }}
                        >
                          Available
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6" style={{ cursor: "pointer" }}>
                    <div className="p-2 m-3 fullclass">
                      <div className="fullclass1">
                        <img
                          src={AI}
                          alt=""
                          style={{
                            width: "40px",
                            height: "40px",
                            marginTop: "5px",
                          }}
                        />
                      </div>
                      <div className="fullclass2">
                        <span className="">Ally Intelligence</span> <br />
                        <span className="description">
                          99.41 % uptime for the last 30 days
                        </span>
                      </div>
                      <div>
                        <img
                          src={Correct}
                          alt=""
                          style={{
                            width: "25px",
                            height: "25px",
                            marginLeft: "18px",
                          }}
                        />{" "}
                        <br />
                        <span
                          style={{
                            color: "gray",
                            fontWeight: "500",
                            fontSize: "14px",
                          }}
                        >
                          Available
                        </span>
                      </div>
                    </div>
                    <div className="p-2 m-3 fullclass">
                      <div className="fullclass1">
                        <img
                          src={Integration2}
                          alt=""
                          style={{
                            width: "40px",
                            height: "40px",
                            marginTop: "5px",
                          }}
                        />
                      </div>
                      <div className="fullclass2">
                        <span className="">Zendesk Integration</span> <br />
                        <span className="description">
                          94.23 % uptime for the last 30 days
                        </span>
                      </div>
                      <div>
                        <img
                          src={Correct}
                          alt=""
                          style={{
                            width: "25px",
                            height: "25px",
                            marginLeft: "18px",
                          }}
                        />{" "}
                        <br />
                        <span
                          style={{
                            color: "gray",
                            fontWeight: "500",
                            fontSize: "14px",
                          }}
                        >
                          Available
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          {sessionStorage.getItem("userRole") != "Ally-Admin" ? (
            <>
              <div className="row mt-4" style={{}}>
                <div className="col-md-6 p-3">
                  {/* <TicketsHeatMap/> */}
                  <HeatMap />
                </div>
                <div className="col-md-6">
                  <ZendeskTickets />
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      )}

      <div className="p-2">
        {activePersona == "Ally-User" && (
          <>
            {/* <JiraAnalytics /> */}
            {/* <ZendeskTickets />

            <ZendeskStackBarChart /> */}
            <div className="row mt-4 p-3">
              <div className="col-md-6 cards">
                <div className="d-flex justify-content-end card-bodys">
                  <img
                    src={Refresh}
                    alt=""
                    style={{ width: "20px", height: "20px", cursor: "pointer" }}
                    onClick={handleRefresh}
                  />
                </div>
                <div className="d-flex justify-content-center mt-1">
                  <div>
                    <img
                      src={Server}
                      alt=""
                      className="img_responsvie"
                      style={{ width: "70px", height: "70px" }}
                    />
                  </div>
                </div>
                <div className="mt-4">
                  <h6
                    style={{
                      fontWeight: "500",
                      textAlign: "center",
                      color: "gray",
                    }}
                  >
                    Gingr Status
                  </h6>
                </div>
                <div className="">
                  <h4 style={{ textAlign: "center", fontWeight: "500" }}>
                    All Systems are up and Running{" "}
                  </h4>
                  <h6
                    style={{
                      textAlign: "center",
                      fontWeight: "500",
                      color: "gray",
                    }}
                  >
                    Last updated today, {lastUpdated1} IST IST{" "}
                  </h6>
                </div>
                <div className="row">
                  <div className="col-md-6" style={{ cursor: "pointer" }}>
                    <div className="p-2 m-3 fullclass">
                      <div className="fullclass1">
                        <img
                          src={ApplicationServer}
                          alt=""
                          style={{
                            width: "40px",
                            height: "40px",
                            marginTop: "5px",
                          }}
                        />
                      </div>
                      <div className="fullclass2">
                        <span className="">Application Server</span> <br />
                        <span className="description">
                          99.93 % uptime for the last 30 days
                        </span>
                      </div>
                      <div>
                        <img
                          src={Correct}
                          alt=""
                          style={{
                            width: "25px",
                            height: "25px",
                            marginLeft: "18px",
                          }}
                        />{" "}
                        <br />
                        <span
                          style={{
                            color: "gray",
                            fontWeight: "500",
                            fontSize: "14px",
                          }}
                        >
                          Available
                        </span>
                      </div>
                    </div>

                    <div className="p-2 m-3 fullclass">
                      <div className="fullclass1">
                        <img
                          src={CommunicationServer}
                          alt=""
                          style={{
                            width: "40px",
                            height: "40px",
                            marginTop: "5px",
                          }}
                        />
                      </div>
                      <div className="fullclass2">
                        <span className="">Communication Server</span> <br />
                        <span className="description">
                          99.93 % uptime for the last 30 days
                        </span>
                      </div>
                      <div>
                        <img
                          src={Correct}
                          alt=""
                          style={{
                            width: "25px",
                            height: "25px",
                            marginLeft: "18px",
                          }}
                        />{" "}
                        <br />
                        <span
                          style={{
                            color: "gray",
                            fontWeight: "500",
                            fontSize: "14px",
                          }}
                        >
                          Available
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6" style={{ cursor: "pointer" }}>
                    <div className="p-2 m-3 fullclass">
                      <div className="fullclass1">
                        <img
                          src={Payments}
                          alt=""
                          style={{
                            width: "40px",
                            height: "40px",
                            marginTop: "5px",
                          }}
                        />
                      </div>
                      <div className="fullclass2">
                        <span className="">Payments Server</span> <br />
                        <span className="description">
                          92.93 % uptime for the last 30 days
                        </span>
                      </div>
                      <div>
                        <img
                          src={Correct}
                          alt=""
                          style={{
                            width: "25px",
                            height: "25px",
                            marginLeft: "18px",
                          }}
                        />{" "}
                        <br />
                        <span
                          style={{
                            color: "gray",
                            fontWeight: "500",
                            fontSize: "14px",
                          }}
                        >
                          Available
                        </span>
                      </div>
                    </div>
                    <div className="p-2 m-3 fullclass">
                      <div className="fullclass1">
                        <img
                          src={MobileApps}
                          alt=""
                          style={{
                            width: "40px",
                            height: "40px",
                            marginTop: "5px",
                          }}
                        />
                      </div>
                      <div className="fullclass2">
                        <span className="">Mobile Applications</span> <br />
                        <span className="description">
                          99.93 % uptime for the last 30 days
                        </span>
                      </div>
                      <div>
                        <img
                          src={Correct}
                          alt=""
                          style={{
                            width: "25px",
                            height: "25px",
                            marginLeft: "18px",
                          }}
                        />{" "}
                        <br />
                        <span
                          style={{
                            color: "gray",
                            fontWeight: "500",
                            fontSize: "14px",
                          }}
                        >
                          Available
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <TicketsHeatMap />
              </div>
            </div>
            <div className="row mt-4" style={{}}>
              <div className="col-md-6">
                {/* <TicketsHeatMap/> */}
                <ZendeskStackBarChart />
                {/* <HeatMap /> */}
              </div>
              <div className="col-md-6">
                <ZendeskTickets />
              </div>
            </div>
          </>
        )}
      </div>

      {activePersona == "Ally-Support" && loader ? (
        <>
          <JiraAnalytics />
        </>
      ) : (
        <>
          {activePersona == "Ally-Support" && (
            <>
              <JiraAnalytics />
            </>
            // <div className="row mt-2" style={{}}>
            //   {data.map((item, index) => (
            //     <div
            //       key={index}
            //       className="col-lg-3 col-md-3 col-sm-6 col-6 custom-col mb-2"
            //     >
            //       <div className="box d-flex align-items-center justify-content-between">
            //         <img
            //           src={item.image}
            //           style={{ width: "40px", height: "40px" }}
            //           alt="itemImage1"
            //         />
            //         <div
            //           className="text-center mt-2"
            //           style={{ color: "#0E2238" }}
            //         >
            //           {item.name}
            //         </div>
            //         <div className="number">{item.number}</div>
            //       </div>
            //     </div>
            //   ))}
            // </div>
          )}

          <div className="p-2">
            {activePersona === "Ally-Admin" && (
              <>
                <h5>Jira Analytics</h5>
                <div>
                  {loading ? (
                    <div
                      className="d-flex justify-content-center align-items-center"
                      style={{ height: "800px" }}
                    >
                      <Spinner animation="border" role="status">
                        <span className="sr-only" style={{ color: "black" }}>
                          Loading...
                        </span>
                      </Spinner>
                    </div>
                  ) : (
                    <>
                      <JiraAnalytics />
                      <ZendeskAverageBarChart />
                      <ZendeskBarChart />
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </>
      )}
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        size="xl"
        style={{ border: "none", height: "1000px" }}
      >
        {/* <Modal.Header
          closeButton
          style={{ backgroundColor: "whitesmoke", border: "1px solid #ccc" }}
        >
          <Modal.Title
            style={{
              color: "#1894af",
              fontFamily: "Poppins",
              fontSize: "18px",
            }}
          >
            Talk to Data
          </Modal.Title>
        </Modal.Header> */}
        <Modal.Body className="p-0">
          {/* Render Chat with fullWidth prop */}
          <Chat fullWidth={true} />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export { Dash };

const dirtyHTML = `
  <h1>Heading</h1>
  <p>Paragraph</p>
`;
const cleanHTML = DOMPurify.sanitize(dirtyHTML, {
  USE_PROFILES: { html: true },
});
