import React, { useState } from "react";
import "./ConnectorsTab.css";
import Teams from "../../assets/images/business.png";
import Slack from "../../assets/images/slack.png";
import Jira from "../../assets/images/jira.png";
import Zendesk from "../../assets/images/zendesk.png";
import Confluence from "../../assets/images/confluence.png";
import ChatGpt from "../../assets/images/cgpt.png";
import LLM from "../../assets/images/llama.png";
import MongoDB from "../../assets/images/mongodb.png";
import MYSQL from "../../assets/images/mysql.png";
import Azure from "../../assets/images/azure.png";
import Pinecone from "../../assets/images/pinecone.png";
export const ConnectorsTab = () => {
  const [showModal, setShowModal] = useState(false);
  let [selectedConnector, setSelectedConnector] = useState("");

  const handleShow = (app) => {
    setShowModal(true);
    setSelectedConnector((selectedConnector = app));
    console.log(selectedConnector, "App");
  };
  const handleClose = () => setShowModal(false);
  const apps = [
    {
      name: "Slack",
      tagline:
        "A powerful team communication platform enabling real-time messaging, collaboration, and efficient project management for teams.",
      logo: Slack,
      connected: false,
    },
    {
      name: "Teams",
      tagline:
        "Microsoft's collaboration tool streamlining chat, video calls, and document sharing for a cohesive teamwork experience.",
      logo: Teams,
      connected: true,
    },
    {
      name: "Zendesk",
      tagline:
        "A customer service platform optimizing support ticket generation and enhancing customer engagement and satisfaction levels.",
      logo: Zendesk,
      connected: false,
    },
    {
      name: "Jira",
      tagline:
        "An agile project management tool for tracking tasks, planning projects, and facilitating enhanced workflow analysis.",
      logo: Jira,
      connected: true,
    },
    {
      name: "Confluence",
      tagline:
        "A team workspace designed for documentation, collaboration, and knowledge sharing to boost organizational efficiency.",
      logo: Confluence,
      connected: false,
    },
    {
      name: "OpenAI",
      tagline:
        "An advanced AI assistant by OpenAI, providing real-time chat responses and intelligent conversation capabilities.",
      logo: ChatGpt,
      connected: true,
    },
    {
      name: "LLM",
      tagline:
        "Large language models developed by OpenAI, enabling sophisticated natural language processing for various applications.",
      logo: LLM,
      connected: false,
    },
    {
      name: "SQL",
      tagline:
        "A robust query language created by IBM for executing complex data operations and developing data-driven solutions.",
      logo: MYSQL,
      connected: true,
    },
    {
      name: "MongoDB",
      tagline:
        "A flexible NoSQL database created by MongoDB Inc., designed for efficient storage of unstructured data.",
      logo: MongoDB,
      connected: false,
    },
    {
      name: "Azure",
      tagline:
        "Microsoft's cloud platform providing scalable solutions for computing, storage, networking, and AI-driven services.",
      logo: Azure,
      connected: false,
    },
    {
      name: "Pinecone",
      tagline:
        "A scalable vector database for fast similarity search and AI-powered applications with high-performance indexing.",
      logo: Pinecone,
      connected: true,
    },
  ];
  const appFormFields = {
    Slack: [
      { name: "workspaceName", label: "Workspace Name", type: "text" },
      { name: "workspaceURL", label: "Workspace URL", type: "url" },
      { name: "clientID", label: "Client ID", type: "text" },
      { name: "clientSecret", label: "Client Secret", type: "password" },
      { name: "botToken", label: "Bot Token", type: "password" },
    ],
    Teams: [
      { name: "teamName", label: "Team Name", type: "text" },
      { name: "adminEmail", label: "Admin Email", type: "email" },
      { name: "clientID", label: "Client ID", type: "text" },
      { name: "clientSecret", label: "Client Secret", type: "password" },
    ],
    Zendesk: [
      { name: "subdomain", label: "Zendesk Subdomain", type: "text" },
      { name: "adminEmail", label: "Admin Email", type: "email" },
      { name: "apiToken", label: "API Token", type: "password" },
    ],
    Jira: [
      { name: "siteURL", label: "Jira Site URL", type: "url" },
      { name: "adminEmail", label: "Admin Email", type: "email" },
      { name: "apiToken", label: "API Token", type: "password" },
    ],
    Confluence: [
      { name: "siteURL", label: "Confluence Site URL", type: "url" },
      { name: "adminEmail", label: "Admin Email", type: "email" },
      { name: "apiToken", label: "API Token", type: "password" },
    ],
    OpenAI: [
      { name: "apiKey", label: "OpenAI API Key", type: "password" },
    ],
    LLM: [
      { name: "apiKey", label: "LLM API Key", type: "password" },
      { name: "modelID", label: "Model ID", type: "text" },
    ],
    SQL: [
      { name: "databaseName", label: "Database Name", type: "text" },
      { name: "host", label: "Host", type: "text" },
      { name: "port", label: "Port", type: "number" },
      { name: "username", label: "Username", type: "text" },
      { name: "password", label: "Password", type: "password" },
    ],
    MongoDB: [
      { name: "clusterURL", label: "Cluster URL", type: "url" },
      { name: "databaseName", label: "Database Name", type: "text" },
      { name: "username", label: "Username", type: "text" },
      { name: "password", label: "Password", type: "password" },
    ],
    Azure: [
      { name: "tenantID", label: "Tenant ID", type: "text" },
      { name: "clientID", label: "Client ID", type: "text" },
      { name: "clientSecret", label: "Client Secret", type: "password" },
      { name: "subscriptionID", label: "Subscription ID", type: "text" },
    ],
    Pinecone: [
      { name: "apiKey", label: "Pinecone API Key", type: "password" },
      { name: "environment", label: "Environment", type: "text" },
      { name: "indexName", label: "Index Name", type: "text" },
    ],
  };
  
  const renderFormFields = () => {
    if (!selectedConnector.name) return null;
    const fields = appFormFields[selectedConnector.name];
    if (!fields) return <p>No form fields available for this app.</p>;

    return fields.map((field) => (
      <div key={field.name} style={{marginTop:"20px"}}>
        <label style={{fontSize:"16px",color:"gray",fontWeight:"600"}}>{field.label}</label>
        <input type={field.type} name={field.name} className="form-control" />
      </div>
    ));
  };
  return (
    <>
      <div className="mt-4" style={{ marginLeft: "24px" }}>
        <h5 className="connectorsIntegrations">Connectors</h5>
      </div>
      <div className="connector-card-container">
        {apps.map((app, index) => (
          <div key={index} className="connector-card">
            <div className="connector-card-header d-flex justify-content-between">
              <span>
                <img
                  src={app.logo}
                  alt={`${app.name} Logo`}
                  className="connector-image"
                />
              </span>

              <button
                className={`connect-btn ${
                  app.connected ? "connected-btn" : ""
                }`}
                onClick={() => {
                  handleShow(app);
                }}
              >
                {app.connected ? "Connected" : "Connect"}
              </button>
            </div>
            <div className="connector-card-body mt-4">
              <h5 className="connector-name">{app.name}</h5>
              <h6 className="connector-tagline">{app.tagline}</h6>
            </div>
            <div className="connector-card-footer mt-4">
              <h6 className="learn-more-text">Get Started **</h6>
            </div>
          </div>
        ))}

        {showModal && (
          <div
            className="modal show fade"
            tabIndex={-1}
            style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
          >
            <div
              className="modal-dialog modal-dialog-centered modal-xl"
              style={{ border: "none" }}
            >
              <div className="modal-content content-modal-data p-4">
                <div className="d-flex ">
                  <img
                    src={selectedConnector.logo}
                    alt=""
                    style={{ width: "40px", height: "40px" }}
                  />
                  <h4 style={{ marginTop: "7px", marginLeft: "20px" }}>
                    {selectedConnector.name}
                  </h4>
                </div>
                <div className="form-section">
                  {renderFormFields()}
                </div>
                <div style={{marginTop:"20px",display:"flex",justifyContent:"end"}}>
                  <button
                    type="button"
                    className="manage-button"
                    onClick={handleClose}
                  >
                    Close
                  </button>
                  <button type="button" className="manage-button">
                    Save changes
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
