import React from "react";

const Indentation = ({ text }: { text: string }) => {
  const linkPattern = /(\b(https?|ftp|file):\/\/[^\s]+)/gi;
  const boldPattern = /\*\*(.*?)\*\*/g;

  const lines = text.split("\n");

  const createMarkup = (line: string) => {
    let formattedLine = line.replace(boldPattern, '$1');
    formattedLine = formattedLine.replace(linkPattern, (url) => {
      return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
    });

    return { __html: formattedLine };
  };

  return (
    <div>
      {lines.map((line, index) => (
        <div
          key={index}
          style={{ marginLeft: `${line.startsWith(">") ? 20 : 0}px` }}
          dangerouslySetInnerHTML={createMarkup(line)} 
        />
      ))}
    </div>
  );
};

export default Indentation;
