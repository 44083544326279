import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";

const ApexChart = ({ data }) => {
  const [chartData, setChartData] = useState({
    options: {
      xaxis: {
        categories: [],
      },
    },
    series: [],
  });
  console.log(data,"Data IN APEX")


  useEffect(() => {
    // Set the chart data dynamically from props when data is received
    console.log(data,"Data")
    setChartData({
      options: {
        xaxis: {
          categories: data.options.xaxis.categories,
        },
      },
      series: data.series,
    });
  }, [data]);

  return (
    <>
      <div style={{ width: "100%" }}>
        <Chart
          options={chartData.options}
          series={chartData.series}
          type="bar"
          height={400}
        />
      </div>
    </>
  );
};

export default ApexChart;
