import React, { useState, useRef, useEffect } from "react";

import moment from "moment-timezone";
import { v4 as uuidv4 } from "uuid";
import userlogo from "../../assets/images/prof.png";
import gingrally from "../../assets/images/gingrally.png";
import TypeWriter from "../Chatpage/TypeWriter.tsx";
import Intendation from "../Chatpage/Intendation.tsx";
import SqlQueryViewer from "../SqlQueryViewer/SqlQueryViewer.js";
import { Chat } from "../Chatpage/Chat.tsx";
// import {ChartComponent}  from ".";
import { ChartComponent } from "./ChartComponent.js";
import { formatDistanceToNow } from "date-fns";
import ApexChart from "./ApexChart.tsx";
import { Brain, PenTool, ChevronDown } from "lucide-react";
import "./ChartComponent.css";
import { useNavigate } from "react-router-dom";
import UploFile from "../Uploadfile/UploFile.tsx";

interface IMessage {
  forEach(arg0: (chat: any) => void): unknown;
  id: string;
  name: string;
  type: string;
  output: React.ReactNode;
  createdAt: string;
  relevantLinks: string[];
  followUpQuestions: string[];
}
export const UpFileChat = ({ data }) => {
  let [nameSpace, setNameSpace] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    console.log(data, "Data is this");
    setNameSpace((nameSpace = data.index_name));
  }, []);
  const [loading, setLoading] = useState(false);
  const [chatScreen, setChatScreen] = useState(false);
  let [showingSuggestions, setShowingSuggestions] = useState(false);

  const currentTime = moment().tz("Asia/Kolkata");
  let [chatIds, setChatId] = useState("");
  const [apiData, setApiData] = useState<any>(null);

  const [apiData1, setApiData1] = useState<any>(null);
  const [apiData2, setApiData2] = useState<any>(null);
  let [inputValue, setInputValue] = useState("");

  let [inputData, setInputData] = useState("");
  const [messages, setMessages] = useState<IMessage[]>([]);

  const [showLoader, setShowLoader] = useState(false);

  const [currentMessageIndex, setCurrentMessageIndex] = useState<number>(-1);
  const [searchTerm, setSearchTerm] = useState("");
  const hours = currentTime.hour();
  const [selectedAgent, setSelectedAgent] = useState("analyst");
  const [showUpload, SetShowUpload] = useState(false);

  const handleChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedAgent(
      selectedValue === "Smart Analyst"
        ? "data_analyst_prompt"
        : "content_writer_prompt"
    );
  };
  let greeting;
  if (hours < 12) {
    greeting = "Good morning";
  } else if (hours < 17) {
    greeting = "Good afternoon";
  } else {
    greeting = "Good evening";
  }

  const [chartData, setChartData] = useState({
    series: [],
    categories: [],
  });

  const handleSendMessage = async () => {
    console.log(chatIds, "Chat Id is");
    console.log(
      `${process.env.REACT_APP_LOCALHOST_API_LINK}/admin_queryFile`,
      "Sql query"
    );

    setApiData([]);
    setApiData1([]);
    setApiData2([]);

    // setShowDiv(!showDiv);

    const content = inputValue.trim();
    if (content) {
      setInputData(content);
      const userMessage: IMessage = {
        id: uuidv4(),
        name: "user",
        type: "user_message",
        output: content,
        createdAt: new Date().toISOString(),
      };
      setMessages((prevMessages) => [...prevMessages, userMessage]);

      const fetchingMessage: IMessage = {
        id: uuidv4(),
        name: "me",
        type: "bot_message",
        output: "Fetching Data..",
        createdAt: new Date().toISOString(),
      };
      setMessages((prevMessages) => [...prevMessages, fetchingMessage]);

      // console.log(content, "Input Data");
      setInputValue(""); // Clear input field
      setShowLoader(true);

      try {
        const token = sessionStorage.getItem("token");
        console.log(chatIds, "Chat id i received is in handle message");
        const link =
          sessionStorage.getItem("userRole") === "Ally-User"
            ? "query-file"
            : "support-query-file";

        const response = await fetch(
          `${process.env.REACT_APP_LOCALHOST_API_LINK}/admin_queryFile`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              query: content,
              namespace: nameSpace,
              context: selectedAgent,
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        if (response.ok) {
          setShowingSuggestions(true);
        }

        const data = await response.json();
        console.log(data, "Data Response");
        const res = data.response;
        // if (
        //   data.message === "An error occurred while processing the request."
        // ) {
        //   setApiData1(null);
        //   console.log("Hello");
        // }
        const sqlquery = data.metadata;
        // console.log(data.metadata, "dta metadata");

        setApiData1(data.metadata);
        console.log();

        const relevantLinks = data.relevantLinks || [];
        const followupQuestions = data.follow_up_questions || [];

        const botMessage: IMessage = {
          id: uuidv4(),
          name: "me",
          type: "bot_message",
          output: res,
          createdAt: new Date().toISOString(),
          isNew: true,
          relevantLinks: relevantLinks,
          followupQuestions: followupQuestions,
          data: data.data,
        };
        console.log(botMessage, "Bot essages");

        setMessages((prevMessages) =>
          prevMessages.map((msg) =>
            msg.id === fetchingMessage.id ? botMessage : msg
          )
        );
        setCurrentMessageIndex(messages.length - 1);
      } catch (error) {
        console.error("Error sending message:", error);

        const errorMessage: IMessage = {
          id: uuidv4(),
          name: "me",
          type: "bot_message",
          output: "There was an error. Can you please retry?",
          createdAt: new Date().toISOString(),
        };

        setMessages((prevMessages) => [...prevMessages, errorMessage]);
        setInputValue("");
      } finally {
        setShowLoader(false);
      }
    }
  };

  const [hasSentInitialMessage, setHasSentInitialMessage] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  useEffect(() => {
    if (!hasSentInitialMessage) {
      setHasSentInitialMessage(true);
      if (textareaRef.current) {
        textareaRef.current.focus();
        setShowSubmit(true);
      }
    }
  }, [hasSentInitialMessage]);

  let [releases, setReleases] = useState("");

  const divRef = useRef<HTMLDivElement>(null);
  const [showButton, setShowButton] = useState(false);
  const checkForScrollbar = () => {
    const div = divRef.current;
    if (div) {
      const isAtBottom =
        div.scrollHeight - div.scrollTop <= div.clientHeight + 1;
      setShowButton(!isAtBottom && div.scrollHeight > div.clientHeight);
    }
  };

  // Scroll event handler
  const handleScroll = () => {
    checkForScrollbar();
  };

  const hideButton = () => {
    const div = divRef.current;
    if (div) {
      div.scrollTop = div.scrollHeight;
      setShowButton(false);
    }
  };

  const scrollToBottom = () => {
    const div = divRef.current;
    if (div) {
      div.scrollTop = div.scrollHeight;
      setShowButton(false);
    }
  };
  const [chats, setChats] = useState<IMessage[]>([]);

  useEffect(() => {
    getChats();
  }, []);
  const getChats = async () => {
    // setLoading(true);
    try {
      const token = sessionStorage.getItem("token");
      const userEmail = sessionStorage.getItem("email");

      const response = await fetch(
        `${process.env.REACT_APP_LOCALHOST_API_LINK}/chat_history/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      console.log(data, "data.MetaData");
      setChats(data.MetaData);
      const currentDate = new Date();
      const uniqueChatsLast15Days = {};

      Object.entries(data.MetaData).forEach(([chatId, chats]) => {
        chats.forEach((chat) => {
          const chatDate = chat.resp_time.split("T")[0];
          const user = chat.user;
          const words = chat.response.split(" ");
          const firstWords =
            user === userEmail ? words.slice(0, 14).join(" ") : null;

          const diffInDays = Math.floor(
            (currentDate - new Date(chatDate)) / (1000 * 60 * 60 * 24)
          );
          if (diffInDays <= 15 && firstWords && words.length >= 0) {
            uniqueChatsLast15Days[chatId] = {
              firstWords,
              createdAt: chat.resp_time,
            };
          }
        });
      });

      const sortChatsByDate = (obj) => {
        return Object.fromEntries(
          Object.entries(obj).sort((a, b) => {
            const timestampA = new Date(a[1].createdAt).getTime();
            const timestampB = new Date(b[1].createdAt).getTime();
            return timestampB - timestampA; // Sort in descending order
          })
        );
      };

      const sortedChats = sortChatsByDate(uniqueChatsLast15Days);
      setUniqueChatsLast15Days(sortedChats);
      setLoading(false);
      // console.warn(sortedChats, "Last 15 Days");
    } catch (error) {
      console.log(error, "Error Loading!!");
    }
  };
  const [filteredChats, setFilteredChats] = useState([]);
  const [uniqueChatsLast15Days, setUniqueChatsLast15Days] = useState({});

  // Set up event listeners and observers
  useEffect(() => {
    const div = divRef.current;
    if (div) {
      div.addEventListener("scroll", handleScroll);
      checkForScrollbar(); // Initial check

      // Optional: Observe changes in content if dynamically updated
      const observer = new MutationObserver(checkForScrollbar);
      observer.observe(div, { childList: true, subtree: true });

      return () => {
        div.removeEventListener("scroll", handleScroll);
        observer.disconnect();
      };
    }
  }, []);
  const handleSearch = (event) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);

    if (!term) {
      setFilteredChats([]);
      return;
    }

    const filterChats = (chats) => {
      return Object.entries(chats)
        .filter(([chatId, chat]) =>
          chat.firstWords.toLowerCase().includes(term)
        )
        .reduce((acc, [chatId, chat]) => ({ ...acc, [chatId]: chat }), {});
    };

    const filtered = filterChats(uniqueChatsLast15Days);
    setFilteredChats(filtered);
  };
  const listChats1 = async () => {
    SetShowUpload(true)
  };
  const renderMessage = (message, index) => {
    const isCurrentMessage = message === messages[messages.length - 1];
    return (
      <li
        key={message.id}
        className={message.type === "user_message" ? "repaly" : "sender"}
      >
        <span>
          <br />
          <p>
            {message.type === "user_message" ? (
              <>
                <span style={{ display: "flex", alignItems: "flex-start" }}>
                  <img
                    src={message.type === "user_message" ? userlogo : gingrally}
                    alt=""
                    width={message.type === "user_message" ? 30 : 70}
                    style={{ marginRight: "10px" }}
                  />
                  <span
                    style={{ flex: 1, textAlign: "justify", marginTop: "4px" }}
                  >
                    {message.output}
                  </span>
                </span>
              </>
            ) : (
              <>
                <span>
                  <img
                    src={message.type === "user_message" ? userlogo : gingrally}
                    alt=""
                    width={message.type === "user_message" ? 30 : 70}
                    style={{ marginBottom: "15px", marginTop: "10px" }}
                  />
                  <span style={{ marginTop: "4px", paddingLeft: "35px" }}>
                    {isCurrentMessage ? (
                      <TypeWriter text={message.output} speed={20} />
                    ) : (
                      <Intendation text={message.output} />
                    )}
                    {message.data && (
                      // <ChartComponent chartData={message.chart_data} />
                      <>
                        {/* <ChartComponent chartData={message.data} /> */}
                        <ApexChart data={message.data} />
                      </>
                    )}
                  </span>
                </span>
              </>
            )}
          </p>
          <br />
        </span>
      </li>
    );
  };
  const chatsToRender = searchTerm ? filteredChats : uniqueChatsLast15Days;
  const sortedChats = Object.entries(uniqueChatsLast15Days)
    .sort((a, b) => new Date(b[1].createdAt) - new Date(a[1].createdAt))
    .slice(0, 2);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const token = sessionStorage.getItem("token");
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_API_LINK}/release_notes/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      console.log(data, "data fetched ");
      if (data) {
        setLink((link = data.url));
        setReleases((releases = data.releases));
      }
      // console.log(link, "link");
    } catch {
      console.log("Error");
    }
  };

  const messagesEndRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState("Smart Analyst");

  const agents = [
    {
      name: "Smart Analyst",
      icon: Brain,
      color: "#6366f1", // Indigo
    },
    {
      name: "Content Writer",
      icon: PenTool,
      color: "#8b5cf6", // Purple
    },
  ];

  const handleClickChatEntry = async (chatId) => {
    console.log(chatId, "Chat Id is");
    setInputValue(chatId);
  };
  let [link, setLink] = useState("");

  const getFormattedTime = (dateString) => {
    const serverDate = new Date(dateString);

    // Convert server date (assumed to be in UTC) to local time
    const localDate = new Date(
      serverDate.getTime() - serverDate.getTimezoneOffset() * 60000
    );

    // console.log("Local Date:", localDate);

    return formatDistanceToNow(localDate, { addSuffix: true });
  };
  return (
    <>
      {" "}
      {!showUpload ? (
        <>
          {" "}
          <div className="container-fluid p-0 m-0">
            <div className=" row p-0 m-0">
              <div className="col-md-2 ">
                <div className="col-12 mt-3 mb-3">
                  <h6
                    className="text-center"
                    style={{
                      color: "var(--text-clr)",
                      fontWeight: "500",
                    }}
                  >
                    Your Chats
                  </h6>
                </div>
                <div className="d-flex justify-content-center">
                  <div className="inputfieldcontainer">
                    <i className="bx bx-search search-icon"></i>
                    <input
                      type="text"
                      className="newinputfield"
                      placeholder="Search your chats..."
                      value={searchTerm}
                      onChange={handleSearch}
                    />
                  </div>
                </div>
                <div
                  className="d-flex justify-content-center"
                  style={{ fontFamily: "Poppins, sans-serif" }}
                >
                  <div className="chat-container1">
                    <div className="previouschatsdiv">
                      {Object.entries(chatsToRender).map(([chatId, chat]) => (
                        <div
                          key={chatId}
                          className="chat-item"
                          onClick={() => {
                            handleClickChatEntry(chat.firstWords);
                          }}
                        >
                          <div
                            className="chat-message"
                            style={{ fontFamily: "poppins" }}
                          >
                            {chat.firstWords ? (
                              <>{chat.firstWords}</>
                            ) : (
                              <>
                                <p>No Chats</p>
                              </>
                            )}{" "}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                {/* <Chat fullWidth={true} submit={true} /> */}
                <div
                  className="mb-4"
                  style={{
                    width: "100%",
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  <h1
                    style={{
                      color: "#1894af",
                      fontWeight: "700",
                      fontSize: "14px",
                      marginBottom: "20px",
                      letterSpacing: "1px",
                      textTransform: "uppercase",
                      textAlign: "center",
                      position: "relative",
                      fontFamily: "Poppins",
                    }}
                  >
                    <span
                      style={{
                        backgroundColor: "#ffffff",
                        padding: "0 10px",
                        position: "relative",
                        zIndex: 1,
                        fontFamily: "Poppins",
                        fontSize: "14px",
                      }}
                    >
                      Product Update
                    </span>
                    <div
                      style={{
                        height: "2px",
                        backgroundColor: "#1894af",
                        position: "absolute",
                        bottom: "0",
                        left: "10%",
                        right: "10%",
                      }}
                    ></div>
                  </h1>

                  <h6
                    style={{
                      fontWeight: "600",
                      color: "#ed2121",
                      fontSize: "12px",
                      marginBottom: "10px",
                      textAlign: "center",
                    }}
                  >
                    Latest Releases
                  </h6>

                  {releases && releases.length > 0 ? (
                    releases.map((release, index) => (
                      <div
                        key={release.id}
                        style={{
                          borderBottom:
                            index !== releases.length - 1
                              ? "1px dashed #ccc"
                              : "none",
                          // position: "relative",
                        }}
                      >
                        <div style={{ marginTop: "10px" }}>
                          <h3
                            style={{
                              color: "gray",
                              fontWeight: "1000",
                              fontSize: "14px",
                              fontFamily: "Poppins",
                            }}
                          >
                            {release.name}
                          </h3>
                          <span
                            style={{
                              color: "gray",
                              fontWeight: "400",
                              fontSize: "12px",
                            }}
                          >
                            Release ID:{" "}
                            <span
                              style={{
                                color: "#1894af",
                                fontWeight: "bold",
                              }}
                            >
                              {release.id}
                            </span>
                          </span>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div
                      style={{
                        textAlign: "center",
                        padding: "50px 0",
                      }}
                    >
                      <div
                        className="spinner-grow text-secondary"
                        role="status"
                        style={{
                          width: "15px",
                          height: "15px",
                          marginRight: "10px",
                        }}
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                      <div
                        className="spinner-grow text-secondary"
                        role="status"
                        style={{
                          width: "15px",
                          height: "15px",
                          marginRight: "10px",
                        }}
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                      <div
                        className="spinner-grow text-secondary"
                        role="status"
                        style={{ width: "15px", height: "15px" }}
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  )}
                  <div className="mt-3">
                    <a
                      href={link || ""}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        color: "red",
                        fontWeight: "800",
                        fontSize: "10px",

                        textAlign: "center",
                        textDecoration: "none",
                        textTransform: "uppercase",
                        letterSpacing: "1px",
                        opacity: "0.8",
                      }}
                    >
                      Check More Releases...
                    </a>
                  </div>
                </div>
              </div>
              <div
                className="col-12 col-md-10 p-0"
                style={{ backgroundColor: "white" }}
              >
                <div className="container-fluid aichatdiv">
                  {loading ? (
                    <>
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ height: "100vh" }}
                      >
                        <div className="spinner-border text-info" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="row">
                        {!chatScreen && (
                          <>
                            <>
                              <div className="col-md-12">
                                <div className="col-12 d-flex justify-content-between">
                                  <div className="agent-wrapper">
                                    <label className="agent-label">
                                      Explore Agent
                                    </label>
                                    <div
                                      className="agent-select-container"
                                      data-selected={selectedAgent}
                                    >
                                      <select
                                        className="agent-select"
                                        value={
                                          selectedAgent === "analyst"
                                            ? "Smart Analyst"
                                            : "Content Writer"
                                        }
                                        onChange={handleChange}
                                      >
                                        <option value="Smart Analyst">
                                          Smart Analyst
                                        </option>
                                        <option value="Content Writer">
                                          Content Writer
                                        </option>
                                      </select>
                                    </div>

                                    {/* <div
                              className="agent-select-container"
                              data-selected="analyst"
                            >
                              <select
                                className="agent-select"
                                onChange={(e) =>
                                  e.target.parentElement.setAttribute(
                                    "data-selected",
                                    e.target.value === "Smart Analyst"
                                      ? "analyst"
                                      : "writer"
                                  )
                                }
                              >
                                <option value="Smart Analyst">
                                  Smart Analyst
                                </option>
                                <option value="Content Writer">
                                  Content Writer
                                </option>
                              </select>
                            </div> */}
                                  </div>
                                  <div>
                                    <p
                                      style={{
                                        marginTop: "25px",
                                        color: "black",
                                      }}
                                    >
                                      {data.filename}
                                    </p>
                                  </div>
                                  <div
                                    className="button-container"
                                    onClick={listChats1}
                                  >
                                    <button className="start-chat-button">
                                      <i
                                        className="bx bx-plus-circle bx-xs"
                                        style={{ marginRight: "5px" }}
                                      ></i>
                                      <span
                                        className="button-text"
                                        style={{ marginTop: "2px" }}
                                      >
                                        Start new chat
                                      </span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div id="Chatpage">
                                <section className="message-area">
                                  <div className="container-fluid p-0 m-0">
                                    <div className="row">
                                      <div className="col-md-12 m-0">
                                        <div className="chat-area p-1">
                                          <div className="chatbox">
                                            <div className="modal-dialog-scrollable">
                                              <div className="modal-content">
                                                <div className="">
                                                  <div className="row">
                                                    <div className="col-8">
                                                      <div className="d-flex align-items-center">
                                                        <span className="chat-icon">
                                                          <img
                                                            className="img-fluid"
                                                            src="https://mehedihtml.com/chatbox/assets/img/arroleftt.svg"
                                                            alt="image title"
                                                          />
                                                        </span>
                                                      </div>
                                                    </div>
                                                    <div className="col-4">
                                                      <ul className="moreoption"></ul>
                                                    </div>
                                                  </div>
                                                </div>

                                                <div
                                                  className="modal-body"
                                                  ref={divRef}
                                                  style={{
                                                    overflowY: "auto",
                                                    height: "100vh",
                                                  }}
                                                >
                                                  <div className="msg-body">
                                                    <ul
                                                      className="messages"
                                                      style={{
                                                        paddingLeft: "0",
                                                      }}
                                                    >
                                                      {messages.map(
                                                        (message, index) =>
                                                          renderMessage(
                                                            message,
                                                            index
                                                          )
                                                      )}
                                                    </ul>
                                                  </div>
                                                </div>
                                                <div className="send-box">
                                                  <form
                                                    onSubmit={(e) => {
                                                      e.preventDefault();
                                                      handleSendMessage();
                                                    }}
                                                  >
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      aria-label="message…"
                                                      placeholder="Ask Ally…"
                                                      onChange={(e) =>
                                                        setInputValue(
                                                          (inputValue =
                                                            e.target.value)
                                                        )
                                                      }
                                                      value={inputValue}
                                                    />
                                                    <button
                                                      type="button"
                                                      onClick={
                                                        handleSendMessage
                                                      }
                                                    >
                                                      <i
                                                        className="fa fa-paper-plane"
                                                        aria-hidden="true"
                                                      />{" "}
                                                    </button>
                                                  </form>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </section>
                              </div>
                            </>
                          </>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
        <UploFile/>
        </>
      )}
    </>
  );
};
