import React, { useState, useEffect, useCallback } from "react";
import Chart from "react-apexcharts";
import "./JiraAnalytics.css";
import Loader from "../../assets/images/LoaderLoading.gif";

const JiraAnalytics = () => {
  const [timeFrame, setTimeFrame] = useState("14"); // Set initial time frame to 14 days
  const [filteredData, setFilteredData] = useState({ Analytics: {} });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('Open'); // Track selected category
  const [selectedCategory1, setSelectedCategory1] = useState('In Progress'); // Track selected category

  const fetchData = async (timeFrame) => {
    setLoading(true);
    try {
      const token = sessionStorage.getItem("token");
      const response = await fetch(`${process.env.REACT_APP_SERVER_API_LINK}/jira_analytics`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
  
      const filterDataByTimeFrame = () => {
        const currentDate = new Date();
        const timeFrameInDays = parseInt(timeFrame, 10); // Convert to integer
        
        // Initialize an object to store the filtered data
        let filteredAnalytics = {};
  
        for (let i = 0; i < timeFrameInDays; i++) {
          // Calculate the date to check
          const checkDate = new Date();
          checkDate.setDate(currentDate.getDate() - i);
          const checkDateString = checkDate.toISOString().split("T")[0]; // Convert to YYYY-MM-DD format
  
          // Include the date in filteredAnalytics even if it doesn't exist in data.Analytics
          filteredAnalytics[checkDateString] = data.Analytics[checkDateString] || {
            Open: { count: 0, priorities: {} },
            InProgress: { count: 0, priorities: {} },
            Resolved: { count: 0, priorities: {} },
            Closed: { count: 0, priorities: {} },
            Draft: { count: 0, priorities: {} },
            InReview: { count: 0, priorities: {} },
          };
        }
  
        return filteredAnalytics;
      };
  
      setFilteredData({ Analytics: filterDataByTimeFrame() });
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };
  
  
  useEffect(() => {
    fetchData(timeFrame);
  }, [timeFrame]);

  const handleChangeTimeFrame = (e) => {
    setTimeFrame(e.target.value);
  };

  const generateChartData = useCallback(() => {
    const categories = Object.keys(filteredData.Analytics).sort((a, b) => new Date(b) - new Date(a));

    // Initialize data arrays for each status category
    const statusCategories = ["Open","In Progress", "Resolved"];
    const series = statusCategories.map(status => ({
      name: status,
      data: categories.map(date => filteredData.Analytics[date]?.[status]?.count || 0),
      color: status === "Open" ? "#ff9900" : status === "In Progress" ? "#33cc33" : "#0066ff",
    }));
    return {
      categories,
      series
    };
  }, [filteredData.Analytics]);

  const generateChartData1 = useCallback(() => {
    const categories = Object.keys(filteredData.Analytics).sort((a, b) => new Date(b) - new Date(a));

    // Initialize data arrays for each status category
    const statusCategories = ["Draft", "In Review","Closed"];
    const series = statusCategories.map(status => ({
      name: status,
      data: categories.map(date => filteredData.Analytics[date]?.[status]?.count || 0),
      color: status === "Draft" ? "#000" : status === "In Review" ? "#db1307" : "#00ffff",
    }));

    return {
      categories,
      series
    };
  }, [filteredData.Analytics]);


  const generateDonutData = useCallback(() => {
    if (!selectedCategory) return [];

    return [
      Object.values(filteredData.Analytics).reduce(
        (sum, day) => sum + (day[selectedCategory]?.priorities?.Blocker || 0),
        0
      ),
      Object.values(filteredData.Analytics).reduce(
        (sum, day) => sum + (day[selectedCategory]?.priorities?.Critical || 0),
        0
      ),
      Object.values(filteredData.Analytics).reduce(
        (sum, day) => sum + (day[selectedCategory]?.priorities?.Major || 0),
        0
      ),
      Object.values(filteredData.Analytics).reduce(
        (sum, day) => sum + (day[selectedCategory]?.priorities?.Trivial || 0),
        0
      ),
      Object.values(filteredData.Analytics).reduce(
        (sum, day) => sum + (day[selectedCategory]?.priorities?.Minor || 0),
        0
      ),
    ];
  }, [filteredData.Analytics, selectedCategory]);
  const generateDonutData1 = useCallback(() => {
    console.log(filteredData.Analytics,"Analytics")
    if (!selectedCategory) return [];

    return [
      Object.values(filteredData.Analytics).reduce(
        (sum, day) => sum + (day[selectedCategory1]?.priorities?.Blocker || 0),
        0
      ),
      Object.values(filteredData.Analytics).reduce(
        (sum, day) => sum + (day[selectedCategory1]?.priorities?.Critical || 0),
        0
      ),
      Object.values(filteredData.Analytics).reduce(
        (sum, day) => sum + (day[selectedCategory1]?.priorities?.Major || 0),
        0
      ),
      Object.values(filteredData.Analytics).reduce(
        (sum, day) => sum + (day[selectedCategory1]?.priorities?.Trivial || 0),
        0
      ),
      Object.values(filteredData.Analytics).reduce(
        (sum, day) => sum + (day[selectedCategory1]?.priorities?.Minor || 0),
        0
      ),
    ];
  }, [filteredData.Analytics, selectedCategory1]);

  const chartData = generateChartData();
  const chartData1 = generateChartData1();
  console.log(chartData1,"Data for the second line chart")
  const donutData = generateDonutData();
  const donutData1 = generateDonutData1();
  const sortedCategories = chartData.categories.sort((a, b) => new Date(a) - new Date(b));

  const lineChartOptions = {
    chart: {
      type: "line",
      toolbar: {
        show: false,
      },
      events: {
        legendClick: (chartContext, seriesIndex, config) => {
          // Prevent the default behavior of disabling the series
          chartContext.toggleSeries = () => { };

          // Get the series name from the clicked legend item
          const statusCategory = chartData.series[seriesIndex].name;
          // Log the clicked series name to confirm the event
          console.log('Legend Clicked:', statusCategory);

          // Update the selected category state based on the clicked legend item
          setSelectedCategory(statusCategory);
        },
      },
    },
    xaxis: {
      categories: sortedCategories,
    },
    yaxis: {
      labels: {
        style: {
          colors: "#6c757d",
        },
      },
    },
    tooltip: {
      enabled: true,
      shared: false,
      intersect: false,
      theme: "dark",
    },
    stroke: {
      width: 2.5,
      curve: "smooth",
      colors: ["#00bcd4", "#8bc34a", "#ff5722"],
    },
    markers: {
      size: 6,
    },
    legend: {
      show: true,
      onItemClick: {
        toggleDataSeries: false, // Disable toggling series visibility on legend click
      },
      onItemHover: {
        highlightDataSeries: true, // Optional: highlight series on hover
      },
      position: "top",
      horizontalAlign: "center",
      fontSize: "14px",
    },
  };

  const lineChartOptions1 = {
    chart: {
      type: "line",
      toolbar: {
        show: false,
      },
      events: {
        legendClick: (chartContext, seriesIndex, config) => {
          // Prevent the default behavior of disabling the series
          chartContext.toggleSeries = () => { };

          // Get the series name from the clicked legend item
          const statusCategory1 = chartData1.series[seriesIndex].name;
          // Log the clicked series name to confirm the event
          console.log('Legend Clicked:', statusCategory1);

          // Update the selected category state based on the clicked legend item
          setSelectedCategory1(statusCategory1);
        },
      },
    },
    xaxis: {
      categories: sortedCategories,
    },
    yaxis: {
      labels: {
        style: {
          colors: "#6c757d",
        },
      },
    },
    tooltip: {
      enabled: true,
      shared: false,
      intersect: false,
      theme: "dark",
    },
    stroke: {
      width: 2.5,
      curve: "smooth",
      colors: ["#00bcd4", "#8bc34a", "#ff5722"],
    },
    markers: {
      size: 6,
    },
    legend: {
      show: true,
      onItemClick: {
        toggleDataSeries: false, // Disable toggling series visibility on legend click
      },
      onItemHover: {
        highlightDataSeries: true, // Optional: highlight series on hover
      },
      position: "top",
      horizontalAlign: "center",
      fontSize: "14px",
    },
  };



  const donutChartOptions = {
    chart: {
      type: "donut",
      toolbar: {
        show: false,
      },
    },
    labels: ["Blocker", "Critical", "Major", "Trivial", "Minor"],
    plotOptions: {
      pie: {
        donut: {
          size: "50%",
          labels: {
            show: true,
            name: {
              fontSize: "16px",
              fontFamily: "Arial, sans-serif",
              color: "#6c757d",
            },
            value: {
              fontSize: "14px",
              fontFamily: "Arial, sans-serif",
              color: "#000",
            },
          },
        },
      },
    },
    colors: ["#ff5722", "#ffeb3b", "#00bcd4", "#8bc34a", "#6c757d"], // Light cool colors
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      fontSize: "14px",
      labels: {
        colors: "#6c757d", // Color of legend labels
      },
    },
    tooltip: {
      enabled: true, // Ensure tooltip is enabled
      theme: "dark",
      y: {
        formatter: (value) => `${value}`, // Format y-axis values in tooltip
      },
    },
  };

  if (loading)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={Loader}
          className="mt-5"
          alt="loaderImage"
          style={{ width: "50px", height: "50px" }}
        />
      </div>
    );
  if (error) return <p>Error loading data: {error.message}</p>;

  return (
    <div>
      <div className="select-container">
        <label style={{ fontWeight: "600", fontFamily: "Poppins" }}>
          Time Frame:
        </label>
        <select onChange={handleChangeTimeFrame} value={timeFrame}>
          <option value="3">3 days</option>
          <option value="7">7 days</option>
          <option value="14">14 days</option>
        </select>
      </div>
      <div className="col-md-12 d-flex">
        <div className="col-md-8">
          <h6
            style={{ fontWeight: "400", fontFamily: "Poppins", color: "gray" }}
          >
            Issue Status Overview
          </h6>
          <Chart
            options={lineChartOptions}
            series={chartData.series}
            type="line"
            height={350}
          />
          <Chart
            options={lineChartOptions1}
            series={chartData1.series}
            type="line"
            height={350}
          />
        </div>
        <div className="col-md-4">
          <h6
            style={{ fontWeight: "400", fontFamily: "Poppins", color: "gray", textAlign: 'center' }}
          >
            Priority Distribution
          </h6>
          <Chart
            options={donutChartOptions}
            series={donutData}
            type="donut"
            height={350}
          />
           <Chart
            options={donutChartOptions}
            series={donutData1}
            type="donut"
            height={350}
          />
        </div>
      </div>
    </div>
  );
};

export default JiraAnalytics;
