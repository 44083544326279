import React, { useState, useEffect, useCallback } from "react";
import Chart from "react-apexcharts";
import Loader from "../../assets/images/LoaderLoading.gif";
import "./HeatMap.css"; // Assuming you have a CSS file for Heatmap

const Heatmap = () => {
  const [timeFrame, setTimeFrame] = useState("14"); // Default time frame is 14 days
  const [filteredData, setFilteredData] = useState({ Analytics: {} });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = async (timeFrame) => {
    setLoading(true);
    try {
      const token = sessionStorage.getItem("token");
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_API_LINK}/jira_analytics`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      console.log(data,"HeatMap Data")

      const filterDataByTimeFrame = () => {
        const currentDate = new Date();
        const timeFrameInDays = parseInt(timeFrame, 10); // Convert to integer

        // Initialize an object to store the filtered data
        let filteredAnalytics = {};

        for (let i = 0; i < timeFrameInDays; i++) {
          // Calculate the date to check
          const checkDate = new Date();
          checkDate.setDate(currentDate.getDate() - i);
          const checkDateString = checkDate.toISOString().split("T")[0]; // Convert to YYYY-MM-DD format

          // Include the date in filteredAnalytics even if it doesn't exist in data.Analytics
          filteredAnalytics[checkDateString] = data.Analytics[
            checkDateString
          ] || {
            Open: { count: 0, priorities: {} },
            InProgress: { count: 0, priorities: {} },
            Resolved: { count: 0, priorities: {} },
            Closed: { count: 0, priorities: {} },
            Draft: { count: 0, priorities: {} },
            InReview: { count: 0, priorities: {} },
          };
        }

        return filteredAnalytics;
      };

      setFilteredData({ Analytics: filterDataByTimeFrame() });
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(timeFrame);
  }, [timeFrame]);

  const handleChangeTimeFrame = (e) => {
    setTimeFrame(e.target.value);
  };

  const generateHeatmapData = useCallback(() => {
    const categories = Object.keys(filteredData.Analytics).sort(
      (a, b) => new Date(b) - new Date(a)
    );

    // Generate data for heatmap, assuming we're visualizing 'count' for each status
    const heatmapSeries = [
      "Open",
      "InProgress",
      "Resolved",
      "Closed",
      "Draft",
      "InReview",
    ].map((status) => ({
      name: status,
      data: categories.map((date) => ({
        x: date,
        y: filteredData.Analytics[date]?.[status]?.count || 0,
      })),
    }));

    return {
      categories,
      series: heatmapSeries,
    };
  }, [filteredData.Analytics]);

  const heatmapData = generateHeatmapData();
  const sortedCategories = heatmapData.categories.sort(
    (a, b) => new Date(a) - new Date(b)
  );

  const heatmapOptions = {
    chart: {
      type: "heatmap",
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: sortedCategories,
    },
    yaxis: {
      labels: {
        style: {
          colors: "#6c757d",
        },
      },
    },
    colors: ["#008FFB"],
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: true,
      theme: "dark",
    },
  };

  if (loading)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={Loader}
          className="mt-5"
          alt="loaderImage"
          style={{ width: "50px", height: "50px" }}
        />
      </div>
    );
  if (error) return <p>Error loading data: {error.message}</p>;

  return (
    <div>
      <div className="select-container">
        <label style={{ fontWeight: "600", fontFamily: "Poppins" }}>
          Time Frame:
        </label>
        <select onChange={handleChangeTimeFrame} value={timeFrame}>
          <option value="3">3 days</option>
          <option value="7">7 days</option>
          <option value="14">14 days</option>
        </select>
      </div>
      <div className="heatmap-container">
        <h6 style={{ fontWeight: "400", fontFamily: "Poppins", color: "gray" }}>
          Jira Issue Heatmap
        </h6>
        <Chart
          options={heatmapOptions}
          series={heatmapData.series}
          type="heatmap"
          height={420}
        />
      </div>
    </div>
  );
};

export default Heatmap;
