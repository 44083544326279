import React, { useState, useRef, useEffect } from "react";
import "./CADashboard.css";
import AllyLogo from "../../assets/images/allylogo.png";
import Users from "../../assets/images/newuser.png";
import Connectors from "../../assets/images/light.png";
import Dashboard from "../../assets/images/home1.png";
import Lock from "../../assets/images/lock.png";
import Onboard from "../../assets/images/onboard.png";
import Register from "../../assets/images/register.png";
import Branch from "../../assets/images/branchh.png";
import Lck from "../../assets/images/lck.png";
import Usersss from "../../assets/images/account.png";
import Setting from "../../assets/images/sett.png";
import InvoiceTable from "./Invoicable.tsx";
import "bootstrap/dist/css/bootstrap.min.css";
import { AccessTab } from "./AccessTab.tsx";
import { ConnectorsTab } from "./ConnectorsTab.tsx";



export const CADashboard = () => {
  const [selectedItem, setSelectedItem] = useState(0);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showLeftButton, setShowLeftButton] = useState(false);
  const [showRightButton, setShowRightButton] = useState(true);
  let [user, setUser] = useState({});
  const [newuser, setNewUser] = useState(true);

  const handleSelect = (index) => {
    setSelectedItem(index);
  };



  const menuItems = [
    { label: "Onboarding", icon: Dashboard },
    { label: "Users", icon: Users },
    { label: "Connectors", icon: Connectors },
    { label: "Access", icon: Lock },
    { label: "Settings", icon: Setting },
  ];




  const containerRef = useRef(null);

  const scrollRight = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        top: 0,
        left: 900, // Scroll by the width of one card
        behavior: "smooth", // Smooth scrolling
      });
    }
  };
  const scrollLeft = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        top: 0,
        left: -900, // Scroll back by the width of one card
        behavior: "smooth", // Smooth scrolling
      });
    }
  };

  const checkScrollButtonsVisibility = () => {
    if (containerRef.current) {
      const { scrollLeft, clientWidth, scrollWidth } = containerRef.current;

      setShowLeftButton(scrollLeft > 0);
      setShowRightButton(scrollLeft + clientWidth < scrollWidth);
    }
  };

  useEffect(() => {
    const container = containerRef.current;

    if (container) {
      checkScrollButtonsVisibility();
      container.addEventListener("scroll", checkScrollButtonsVisibility);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", checkScrollButtonsVisibility);
      }
    };
  }, []);



  return (
    <div className="container-fluid containerthing p-0 m-0">
      <div className="row p-0 m-0">
        <div className="col-md-2 p-0 m-0" >
          <div className="sidebar">
            <li className="mb-5 mt-3 d-flex ml-5" style={{marginLeft:"30px"}}>
              <img src={AllyLogo} alt="" className="image_responsive" />
              <h5 style={{ marginLeft: "10px", fontWeight: "600" }}>
                Business
              </h5>
            </li>
            <ul className="menu">
              {menuItems.map((item, index) => (
                <li
                  key={index}
                  className={`menu-item ${
                    selectedItem === index ? "selected" : ""
                  }`}
                  onClick={() => handleSelect(index)}
                >
                  <a className="label-names">
                    <img src={item.icon} alt="" className="listImages" />
                    {item.label}
                  </a>
                </li>
              ))}
            </ul>
            <li className="mb-5 mt-3 d-flex justify-content-center">
              <div className="cardish">
                <div className="avatar">
                  <span className="avatar-text">AB</span>
                </div>
                <div className="info">
                  <h3 className="name">User</h3>
                  <p className="company">ABC Org</p>
                </div>
              </div>
            </li>
          </div>
        </div>
        <div
          className="col-md-10"
          style={{
            msOverflowStyle: "none",
            scrollbarWidth: "none",
            height: "100vh",
            overflow: "hidden",
          }}
        >
          <div className="main-contents p-0 m-0">
            {selectedItem === 0 && (
              <>
                <div
                  className="col-md-12 d-flex align-items-center"
                  style={{ height: "100vh" }}
                >
                  {showLeftButton && (
                    <button className="scroll-button left" onClick={scrollLeft}>
                      <span style={{ color: "gray" }}>&#x2190;</span>{" "}
                    </button>
                  )}
                  <div
                    className="card-container"
                    ref={containerRef}
                    style={{
                      position: "relative",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      width: "100%",
                    }}
                  >
                    <div className="newcard">
                      <div>
                        <img
                          src={Onboard}
                          alt=""
                          style={{
                            width: "40px",
                            height: "40px",
                            marginBottom: "20px",
                          }}
                        />
                        <h5 style={{fontWeight:"600",color:''}}>Organization Onboard</h5>
                        <p>
                          Start onboarding your organization for streamlined
                          operations.
                        </p>
                        <button
                          type="button"
                          className="btn  get-started-button"
                        >
                          Get Started
                        </button>
                      </div>
                    </div>
                    <div className="newcard">
                      <div>
                        <i className="icon-classname"></i>
                        <img
                          src={Register}
                          alt=""
                          style={{
                            width: "40px",
                            height: "40px",
                            marginBottom: "20px",
                          }}
                        />

                        <h5>Register Users</h5>
                        <p>Register your team members to grant them access.</p>
                        <button
                          type="button"
                          className="btn btn-outline-primary get-started-button"
                        >
                          Get Started
                        </button>
                      </div>
                    </div>
                    <div className="newcard">
                      <div>
                        <i className="icon-classname"></i>
                        <img
                          src={Branch}
                          alt=""
                          style={{
                            width: "40px",
                            height: "40px",
                            marginBottom: "20px",
                          }}
                        />

                        <h5>Integrate Connectors</h5>
                        <p>Connect your systems for seamless data exchange.</p>
                        <button
                          type="button"
                          className="btn btn-outline-primary get-started-button"
                        >
                          Get Started
                        </button>
                      </div>
                    </div>
                    <div className="newcard">
                      <div>
                        <i className="icon-classname"></i>
                        <img
                          src={Lck}
                          alt=""
                          style={{
                            width: "40px",
                            height: "40px",
                            marginBottom: "20px",
                          }}
                        />

                        <h5>Manage Access</h5>
                        <p>Control user privileges and manage access levels.</p>
                        <button
                          type="button"
                          className="btn btn-outline-primary get-started-button"
                        >
                          Get Started
                        </button>
                      </div>
                    </div>
                    <div className="newcard">
                      <div>
                        <i className="icon-classname"></i>
                        <img
                          src={Usersss}
                          alt=""
                          style={{
                            width: "40px",
                            height: "40px",
                            marginBottom: "20px",
                          }}
                        />

                        <h5>Setup Account</h5>
                        <p>
                          Complete the initial setup to activate your account.
                        </p>
                        <button
                          type="button"
                          className="btn btn-outline-primary get-started-button"
                        >
                          Get Started
                        </button>
                      </div>
                    </div>
                  </div>
                  {showRightButton && (
                    <button
                      className="scroll-button right"
                      onClick={scrollRight}
                    >
                      <span style={{ color: "gray" }}>&#x2192;</span>
                    </button>
                  )}
                </div>
              </>
            )}
            {selectedItem === 3 && (
              <><AccessTab/></>
            )}
            {selectedItem === 2 && (
              <>
              <ConnectorsTab/>
              </>
            )}
            {selectedItem === 1 && (
              <>
                <InvoiceTable/>
              </>
            )}
          </div>
        </div>
      </div>
    
    </div>

    
  );
};
