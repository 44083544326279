import React from "react";
import howallyworks from "../../assets/images/Homepage/howallyworks.png";
import IntuitiveAIInterface from "../../assets/images/icons/IntuitiveAIInterface.png";
import CustomizedDataExploration from "../../assets/images/icons/CustomizedDataExploration.png";
import ContextualUnderstanding from "../../assets/images/icons/ContextualUnderstanding.png";
import ContinuousLearning from "../../assets/images/icons/ContinuousLearning.png";
import DynamicDecisionSupport from "../../assets/images/icons/DynamicDecisionSupport.png";
import SmartAutomation from "../../assets/images/icons/SmartAutomation.png";
import FlowChart from "../../assets/images/Homepage/FlowChart.png";
import { Link } from "react-router-dom";
export const Homepage = () => {
  return (
    <div>
      {/* Insight */}
      <div id="insight">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="Insights-sec">
                <div className="title">
                  <h2>
                    Ally's on Your Team <br />
                    From Quick Insights to Full Support
                  </h2>
                </div>
                <div className="cont">
                  <p>
                    Meet Ally! it acts as a dynamic AI team member, adept at
                    analyzing documents, providing interactive responses, and
                    offering tailored support across various roles like
                    Developer, Support Executive, and Manager. It streamlines
                    interactions, making information retrieval and task
                    execution intuitive and efficient. With Ally, users
                    experience a blend of precise document insights and
                    adaptable persona-based support, ensuring a cohesive,
                    smarter work environment where technology enhances human
                    capabilities.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Get Started */}
      <div id="getstared">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="getstarted_sec">
                <button>
                  <Link to="/login" style={{ color: "#ffffff" }}>
                    Get Started ...
                  </Link>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* How Ally Works */}

      <div id="howallywrks">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="howally_img">
                <img src={howallyworks} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Unfold-sec */}
      <div id="unfold_sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="title">
                <h4>
                  Ally Unfolded: Navigating <span>Through AI Brilliance</span>{" "}
                </h4>
              </div>
              <div className="cnt">
                <p>
                  Dive into the essence of Ally where we showcase the seamless
                  journey from document upload to insightful interactions.
                  Experience firsthand how Ally's intelligent processes
                  transform complex documents into actionable knowledge, guiding
                  you every step of the way. This journey isn't just about
                  understanding documents; it's about unlocking a world of
                  possibilities, making every interaction with Ally a step
                  towards unparalleled clarity and efficiency. Join us on this
                  adventure and see how Ally turns AI into your most trusted
                  ally.
                </p>
              </div>
            </div>
          </div>
          <div className="row srv">
            <div className="col-md-4">
              <div>
                <article className="card">
                  <header className="card__thumb">
                    <a href="#">
                      <img src={IntuitiveAIInterface} />
                    </a>
                  </header>

                  <div className="card__body">
                    <h2 className="card__title">
                      <a href="#">Intuitive AI Interface</a>
                    </h2>

                    <p className="card__description">
                      Ally's adaptive interface makes navigating complex data as
                      simple as a conversation, tailoring AI's power to your
                      needs.
                    </p>
                  </div>
                </article>
              </div>
            </div>
            <div className="col-md-4">
              <div>
                <article className="card">
                  <header className="card__thumb">
                    <a href="#">
                      <img src={CustomizedDataExploration} />
                    </a>
                  </header>
                  {/* <date class="card__date">
                      <span class="card__date__day">11</span>
                      <br />
                      <span class="card__date__month">Jan</span>
                    </date> */}
                  <div className="card__body">
                    {/* <div class="card__category">
                        <a href="#">pet</a>
                      </div> */}
                    <h2 className="card__title">
                      <a href="#">Customized Data Exploration</a>
                    </h2>

                    <p className="card__description">
                      Discover insights tailored to your unique needs with
                      Ally's precision in slicing through data landscapes.
                    </p>
                  </div>
                </article>
              </div>
            </div>
            <div className="col-md-4">
              <div>
                <article className="card">
                  <header className="card__thumb">
                    <a href="#">
                      <img src={DynamicDecisionSupport} />
                    </a>
                  </header>

                  <div className="card__body">
                    <h2 className="card__title">
                      <a href="#">Dynamic Decision Support</a>
                    </h2>

                    <p className="card__description">
                      Make informed strategic decisions powered by Ally's
                      real-time, actionable recommendations and predictive
                      insights.
                    </p>
                  </div>
                </article>
              </div>
            </div>
            {/*  */}
            <div className="col-md-4">
              <div>
                <article class="card">
                  <header class="card__thumb">
                    <a href="#">
                      <img src={SmartAutomation} />
                    </a>
                  </header>

                  <div class="card__body">
                    <h2 class="card__title">
                      <a href="#">Smart Automation</a>
                    </h2>

                    <p className="card__description">
                      Ally automates routine to complex tasks with unmatched
                      accuracy, freeing up creativity and innovation within your
                      team.
                    </p>
                  </div>
                </article>
              </div>
            </div>
            <div className="col-md-4">
              <div>
                <article class="card">
                  <header class="card__thumb">
                    <a href="#">
                      <img src={ContextualUnderstanding} />
                    </a>
                  </header>

                  <div className="card__body">
                    <h2 className="card__title">
                      <a href="#">Contextual Understanding</a>
                    </h2>

                    <p className="card__description">
                      Beyond data analysis, Ally offers deep insights by
                      understanding the context, connecting dots for strategic
                      actions.
                    </p>
                  </div>
                </article>
              </div>
            </div>
            <div className="col-md-4">
              <div>
                <article className="card">
                  <header className="card__thumb">
                    <a href="#">
                      <img src={ContinuousLearning} />
                    </a>
                  </header>

                  <div className="card__body">
                    <h2 className="card__title">
                      <a href="#">Continuous Learning</a>
                    </h2>

                    <p className="card__description">
                      With each interaction, Ally grows smarter, ensuring your
                      AI solutions evolve at the pace of your business.
                    </p>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* FlowChart */}
      {/* <div id="flowchart">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="flow_title">
                <h2>
                  IntelliFlow - Navigating <br /> The Architecture Of Ally
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="flow-img">
                <img src={FlowChart} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* Use case scenarios */}
      <div id="usecase">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="title_sec">
                <h4>
                  Use case <span>scenarios</span>
                </h4>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="card-sec">
                <div className="icn">
                  <i class="fa fa-headphones" aria-hidden="true"></i>
                </div>
                <div className="cnt">
                  <h4>Real-time Collaboration Enhancement</h4>
                  <p>
                    Ally transforms team projects by synthesizing input from
                    various members in real-time, identifying key tasks, and
                    suggesting optimized paths for project completion. Its like
                    having an AI project manager streamlining workflows and
                    enhancing productivity.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card-sec">
                <div className="icn">
                  <i class="fa fa-tasks" aria-hidden="true"></i>
                </div>
                <div className="cnt">
                  <h4>Seamless Project Integration</h4>
                  <p>
                    Navigate through the complexities of integrating new
                    software or systems with Ally’s guided insights. It analyzes
                    compatibility, suggests optimal integration paths, and
                    identifies potential pitfalls, ensuring smooth adoption and
                    minimizing disruptions
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card-sec">
                <div className="icn">
                  <i class="fa fa-envelope-o" aria-hidden="true"></i>
                </div>
                <div className="cnt">
                  <h4>
                    Customized Learning Paths for Continuous Professional Growth
                  </h4>
                  <p>
                    Ally doesnt just onboard; it personalizes learning journeys
                    for each team member, adapting content to meet their
                    evolving professional needs. This ensures everyone is not
                    only up to speed but also continuously advancing in their
                    skills and knowledge.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="card-sec">
                <div className="icn">
                  <i class="fa fa-user" aria-hidden="true"></i>
                </div>
                <div className="cnt">
                  <h4>Intelligent Resource Allocation</h4>
                  <p>
                    Ally analyzes project requirements, team strengths, and
                    deadlines to recommend the most efficient allocation of
                    resources. Its like having an AI operations manager ensuring
                    your projects are well-staffed and your talent is optimally
                    utilized.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
