import React, { useState, useEffect } from "react";

const TypeWriter = ({ text, speed }: { text: string; speed: number }) => {
  const [lines, setLines] = useState<string[]>([]);
  const [isTyping, setIsTyping] = useState(true);
  const [showCursor, setShowCursor] = useState(true);
  const [hasReloaded, setHasReloaded] = useState(false); 

  // Regular expression to match links
  const linkPattern = /(\b(https?|ftp|file):\/\/[^\s]+)/gi;
  // Regular expression to remove ** for bold
  const boldPattern = /\*\*(.*?)\*\*/g;

  useEffect(() => {
    if (!text) {
      alert("No Data Available / Error in Sql query");
      if (!hasReloaded) {
        setHasReloaded(true);
        window.location.reload();
      }
      return;
    }
    console.log(text, "String Text Received");

    // Split text into lines and process them for bold and links
    const processText = (text: string) => {
      const linesArray = text.split("\n").map((line) => {
        // First remove bold **, then replace links
        let formattedLine = line.replace(boldPattern, "$1");
        formattedLine = formattedLine.replace(linkPattern, (url) => {
          return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
        });
        return formattedLine;
      });
      return linesArray;
    };

    const linesArray = processText(text);

    if (!text) {
      window.alert("Error in Fetching data");
    }

    let currentLine = 0;
    let currentChar = 0;
    const interval = setInterval(() => {
      if (currentLine < linesArray.length) {
        const line = linesArray[currentLine];
        setLines((prevLines) => {
          const newLines = [...prevLines];
          newLines[currentLine] = line.substring(0, currentChar);
          return newLines;
        });
        if (currentChar < line.length) {
          currentChar++;
        } else {
          currentChar = 0;
          currentLine++;
        }
      } else {
        setIsTyping(false);
        clearInterval(interval);
      }
    }, speed);

    return () => clearInterval(interval);
  }, [text, speed]);

  useEffect(() => {
    const cursorInterval = setInterval(() => {
      setShowCursor((prevShowCursor) => !prevShowCursor);
    }, 500);

    return () => clearInterval(cursorInterval);
  }, []);

  return (
    <div>
      {lines.map((line, index) => (
        <div
          key={index}
          dangerouslySetInnerHTML={{ __html: line }}
          // style={{ marginLeft: `${line.startsWith(">") ? 20 : 0}px` }}
        />
      ))}
      
    </div>
  );
};

export default TypeWriter;
