import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import Loader from "../../assets/images/LoaderLoading.gif";

const TicketsHeatMap: React.FC = () => {
  const [timeFrame, setTimeFrame] = useState("30"); // Default time frame in days
  const [filteredData, setFilteredData] = useState<any>(null);
  const [loading, setLoading] = useState(false);  
  const [error, setError] = useState<string | null>(null);

  const timeFrameOptions = ["30", "15", "10", "7"]; // Dynamic time frame options in days

  // Function to fetch data from API
  const fetchData = async (timeFrame: string) => {
    setLoading(true);
    try {
      const token = sessionStorage.getItem("token");
      const response = await fetch(`${process.env.REACT_APP_SERVER_API_LINK}/jira_analytics`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();

      // Filter data by time frame
      const filterDataByTimeFrame = () => {
        const currentDate = new Date();
        const timeFrameInDays = parseInt(timeFrame, 10); // Convert time frame to integer

        let filteredAnalytics: any = {};

        for (let i = 0; i < timeFrameInDays; i++) {
          const checkDate = new Date();
          checkDate.setDate(currentDate.getDate() - i);
          const checkDateString = checkDate.toISOString().split("T")[0]; // Format to YYYY-MM-DD

          // Include the date in filteredAnalytics, handle missing data gracefully
          filteredAnalytics[checkDateString] = data.Analytics[checkDateString] || {
            Open: { count: 0, priorities: {} },
            InProgress: { count: 0, priorities: {} },
            Resolved: { count: 0, priorities: {} },
            Closed: { count: 0, priorities: {} },
            Draft: { count: 0, priorities: {} },
            InReview: { count: 0, priorities: {} },
          };
        }

        return filteredAnalytics;
      };

      setFilteredData({ Analytics: filterDataByTimeFrame() });
      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(timeFrame);
  }, [timeFrame]);

  // Handle time frame change
  const handleChangeTimeFrame = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setTimeFrame(event.target.value);
  };

  // Dynamically generate heatmap data
  const generateData = () => {
    if (!filteredData) return [];

    const statuses = Object.keys(filteredData.Analytics[Object.keys(filteredData.Analytics)[0]] || {});
    const prioritiesSet = new Set<string>();

    // Collect all unique priorities across all statuses
    Object.keys(filteredData.Analytics).forEach((date) => {
      statuses.forEach((status) => {
        const priorities = filteredData.Analytics[date][status]?.priorities;
        if (priorities) {
          Object.keys(priorities).forEach((priority) => prioritiesSet.add(priority));
        }
      });
    });

    const priorities = Array.from(prioritiesSet); // Unique priorities

    return priorities.map((priority) => ({
      name: priority,
      data: Object.keys(filteredData.Analytics).map((date) => {
        let yValue = 0;
        statuses.forEach((status) => {
          yValue += filteredData.Analytics[date][status]?.priorities[priority] || 0;
        });
        return {
          x: date,
          y: yValue,
        };
      }),
    }));
  };

  const getColorRange = () => {
    return [
      "#FDEDED",
      "#FBD6D6",
      "#F9C0C0",
      "#F8A3A3",
      "#F57F7F",
      "#F33F36",
      "#E60012",
    ];
  };

  const colorRange = getColorRange().map((color, index) => ({
    from: index * 4,
    to: (index + 1) * 4,
    color,
  }));

  const series = generateData();

  const options = {
    chart: {
      type: "heatmap",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      heatmap: {
        shadeIntensity: 0.6,
        colorScale: {
          ranges: colorRange,
        },
        distributed: true,
        enableShades: false,
        borderColor: "transparent",
        radius: 0,
      },
    },
    stroke: {
      show: false,
    },
    grid: {
      show: false,
      borderColor: "transparent",
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: "category",
      labels: {
        rotate: -45,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    title: {
      align: "center",
      style: {
        fontSize: "24px",
        fontWeight: "bold",
        color: "#333",
      },
    },
    legend: {
      show: true,
      position: "right",
      offsetY: 40,
      height: 230,
      labels: {
        useSeriesColors: false,
        colors: "#808080",
      },
    },
  };

  if (loading)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          src={Loader}
          className="mt-5"
          alt="loaderImage"
          style={{ width: "50px", height: "50px" }}
        />
      </div>
    );
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="">   {/*cards classname*/}
      <div className="col-md-12 card-bodys p-3">
        <h6 style={{ color: "gray", marginLeft: "11px", textAlign: "center" }}>
          My Tickets
        </h6>
        <div className="select-container">
          <label style={{ fontWeight: "600", fontFamily: "Poppins" }}>
            Time Frame:
          </label>
          <select
            name="status-select"
            id="status-select"
            className="mt-3 mr-3 custom-select"
            value={timeFrame}
            onChange={handleChangeTimeFrame}
            style={{ marginLeft: "10px" }}
          >
            {timeFrameOptions.map((option, index) => (
              <option key={index} value={option}>
                {option} Days
              </option>
            ))}
          </select>
        </div>
        <Chart options={options} series={series} type="heatmap" height={350} />
      </div>
    </div>
  );
};

export default TicketsHeatMap;