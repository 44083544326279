import "./Invoicable.css";
import React, { useState } from "react";
import Teams from "../../assets/images/business.png";
import Slack from "../../assets/images/slack.png";
import Jira from "../../assets/images/jira.png";
import Zendesk from "../../assets/images/zendesk.png";
import Confluence from "../../assets/images/confluence.png";
import ChatGpt from "../../assets/images/cgpt.png";
import LLM from "../../assets/images/llama.png";
import MongoDB from "../../assets/images/mongodb.png";
import MYSQL from "../../assets/images/mysql.png";
import Azure from "../../assets/images/azure.png";
import Pinecone from "../../assets/images/pinecone.png";
import Apps from "../../assets/images/apps.png";

const AccessManagementTable = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10;
  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);
  const [selectedUser, setSelectedUser] = useState({});

  const data = [
    {
      userId: "USR_1001",
      name: "John Doe",
      role: "Admin",
      accessStatus: "Active",
      lastLogin: "2024-10-01",
      manage: (
        <div>
          <button className="manage-button" onClick={handleShow}>
            View
          </button>
        </div>
      ),
      apps: (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <img
            src={Teams}
            alt=""
            style={{ width: "20px", height: "20px", marginRight: "10px" }}
          />
          <img
            src={Slack}
            alt=""
            style={{ width: "20px", height: "20px", marginRight: "10px" }}
          />
          <img
            src={Jira}
            alt=""
            style={{ width: "20px", height: "20px", marginRight: "10px" }}
          />
          <img
            src={Confluence}
            alt=""
            style={{ width: "20px", height: "20px", marginRight: "10px" }}
          />
          <img
            src={Zendesk}
            alt=""
            style={{ width: "20px", height: "20px", marginRight: "10px" }}
          />
          <img
            src={LLM}
            alt=""
            style={{ width: "20px", height: "20px", marginRight: "10px" }}
          />
          <img
            src={MYSQL}
            alt=""
            style={{ width: "20px", height: "20px", marginRight: "10px" }}
          />
        </div>
      ),
    },
    {
      userId: "USR_1002",
      name: "Jane Smith",
      role: "Manager",
      accessStatus: "Pending",
      lastLogin: "2024-09-30",
      manage: (
        <div>
          <button className="manage-button">View</button>
        </div>
      ),
      apps: (
        <div>
          <img
            src={Teams}
            alt=""
            style={{ width: "20px", height: "20px", marginRight: "10px" }}
          />
          <img
            src={Slack}
            alt=""
            style={{ width: "20px", height: "20px", marginRight: "10px" }}
          />
        </div>
      ),
    },
    {
      userId: "USR_1003",
      name: "Michael Brown",
      role: "Developer",
      accessStatus: "Active",
      lastLogin: "2024-09-28",
      manage: (
        <div>
          <button className="manage-button">View</button>
        </div>
      ),
      apps: (
        <div>
          <img
            src={Zendesk}
            alt=""
            style={{ width: "20px", height: "20px", marginRight: "10px" }}
          />
          <img
            src={Jira}
            alt=""
            style={{ width: "20px", height: "20px", marginRight: "10px" }}
          />
        </div>
      ),
    },
    {
      userId: "USR_1004",
      name: "Sara Lee",
      role: "Support",
      accessStatus: "Inactive",
      lastLogin: "2024-08-15",
      manage: (
        <div>
          <button className="manage-button">View</button>
        </div>
      ),
      apps: (
        <div>
          <img
            src={Confluence}
            alt=""
            style={{ width: "20px", height: "20px", marginRight: "20px" }}
          />
          <img
            src={ChatGpt}
            alt=""
            style={{ width: "20px", height: "20px", marginRight: "10px" }}
          />
        </div>
      ),
    },
    {
      userId: "USR_1005",
      name: "Daniel Adams",
      role: "HR",
      accessStatus: "Pending",
      lastLogin: "2024-09-22",
      manage: (
        <div>
          <button className="manage-button">View</button>
        </div>
      ),
      apps: (
        <div>
          <img
            src={LLM}
            alt=""
            style={{ width: "20px", height: "20px", marginRight: "10px" }}
          />
          <img
            src={MYSQL}
            alt=""
            style={{ width: "20px", height: "20px", marginRight: "10px" }}
          />
        </div>
      ),
    },
    {
      userId: "USR_1006",
      name: "Nancy White",
      role: "Admin",
      accessStatus: "Active",
      lastLogin: "2024-10-03",
      manage: (
        <div>
          <button className="manage-button">View</button>
        </div>
      ),
      apps: (
        <div>
          <img
            src={Teams}
            alt=""
            style={{ width: "20px", height: "20px", marginRight: "10px" }}
          />
          <img
            src={Slack}
            alt=""
            style={{ width: "20px", height: "20px", marginRight: "10px" }}
          />
        </div>
      ),
    },
    {
      userId: "USR_1007",
      name: "Eli Johnson",
      role: "Support",
      accessStatus: "Inactive",
      lastLogin: "2024-07-12",
      manage: (
        <div>
          <button className="manage-button">View</button>
        </div>
      ),
      apps: (
        <div>
          <img
            src={Teams}
            alt=""
            style={{ width: "20px", height: "20px", marginRight: "10px" }}
          />
          <img
            src={Slack}
            alt=""
            style={{ width: "20px", height: "20px", marginRight: "10px" }}
          />
        </div>
      ),
    },
    {
      userId: "USR_1007",
      name: "Eli Johnson",
      role: "Support",
      accessStatus: "Inactive",
      lastLogin: "2024-07-12",
      manage: (
        <div>
          <button className="manage-button">View</button>
        </div>
      ),
      apps: (
        <div>
          <img
            src={Teams}
            alt=""
            style={{ width: "20px", height: "20px", marginRight: "10px" }}
          />
          <img
            src={Slack}
            alt=""
            style={{ width: "20px", height: "20px", marginRight: "10px" }}
          />
        </div>
      ),
    },
    {
      userId: "USR_1007",
      name: "Eli Johnson",
      role: "Support",
      accessStatus: "Inactive",
      lastLogin: "2024-07-12",
      manage: (
        <div>
          <button className="manage-button">View</button>
        </div>
      ),
      apps: (
        <div>
          <img
            src={Teams}
            alt=""
            style={{ width: "20px", height: "20px", marginRight: "10px" }}
          />
          <img
            src={Slack}
            alt=""
            style={{ width: "20px", height: "20px", marginRight: "10px" }}
          />
        </div>
      ),
    },
    {
      userId: "USR_1007",
      name: "Eli Johnson",
      role: "Support",
      accessStatus: "Inactive",
      lastLogin: "2024-07-12",
      manage: (
        <div>
          <button className="manage-button">View</button>
        </div>
      ),
      apps: (
        <div>
          <img
            src={Teams}
            alt=""
            style={{ width: "20px", height: "20px", marginRight: "10px" }}
          />
          <img
            src={Slack}
            alt=""
            style={{ width: "20px", height: "20px", marginRight: "10px" }}
          />
        </div>
      ),
    },

    {
      userId: "USR_1007",
      name: "Eli Johnson",
      role: "Support",
      accessStatus: "Inactive",
      lastLogin: "2024-07-12",
      manage: (
        <div>
          <button className="manage-button">View</button>
        </div>
      ),
      apps: (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            width: "200px",
            height: "20px",
          }}
        >
          <img
            src={Jira}
            alt=""
            style={{ width: "20px", height: "20px", marginRight: "10px" }}
          />
          {/* <img
            src={Teams}
            alt=""
            style={{ width: "20px", height: "20px", marginRight: "20px",marginBottom:"10px"}}
          />
          <img
            src={Slack}
            alt=""
            style={{ width: "20px", height: "20px", marginRight: "20px" }}
          />
          <img
            src={Jira}
            alt=""
            style={{ width: "20px", height: "20px", marginRight: "10px" }}
          />
          <img
            src={Teams}
            alt=""
            style={{ width: "20px", height: "20px", marginRight: "20px",}}
          />
          <img
            src={Slack}
            alt=""
            style={{ width: "20px", height: "20px", marginRight: "20px" }}
          />
          <img
            src={Confluence}
            alt=""
            style={{ width: "20px", height: "20px", marginRight: "10px" }}
          /> */}
        </div>
      ),
    },
  ];

  const filteredData = data.filter(
    (row) =>
      row.userId.toLowerCase().includes(searchTerm.toLowerCase()) ||
      row.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      row.role.toLowerCase().includes(searchTerm.toLowerCase()) ||
      row.accessStatus.toLowerCase().includes(searchTerm.toLowerCase()) ||
      row.lastLogin.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredData.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(filteredData.length / rowsPerPage);

  return (
    <div className="col-md-12 p-4 mt-4">
      <div className="d-flex justify-content-between">
        <h6 style={{ marginTop: "15px" }}>All Users <span style={{color:"gray"}}>{data.length}</span></h6>

        <div>
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="search-bar"
          />
          <button className="manage-button">Add User</button>
        </div>
      </div>
      <div>
        <table style={{ width: "100%" }}>
          <thead>
            <tr>
              <th>User ID</th>
              <th>Name</th>
              <th>Role</th>
              <th>Last Login</th>

              <th>Apps</th>
              <th>Access Status</th>
              <th>Manage Access</th>
            </tr>
          </thead>
          <tbody>
            {currentRows.map((row, index) => (
              <tr key={index}>
                <td>
                  <a href="#">{row.userId}</a>
                </td>
                <td>{row.name}</td>
                <td>{row.role}</td>
                <td>{row.lastLogin}</td>

                <th style={{ textAlign: "center" }}>{row.apps}</th>

                <td>
                  <p
                    className={`status status-${row.accessStatus.toLowerCase()}`}
                  >
                    {row.accessStatus}
                  </p>
                </td>
                <td>{row.manage}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="pagination-container">
        <span className="pagination-info">
          Showing {indexOfFirstRow + 1} to{" "}
          {Math.min(indexOfLastRow, filteredData.length)} of{" "}
          {filteredData.length} entries
        </span>
        <div className="pagination-buttons">
          <button
            className="pagination-button"
            disabled={currentPage === 1}
            onClick={() => setCurrentPage((prev) => prev - 1)}
          >
            Previous
          </button>
          <span className="pagination-info">
            {currentPage} of {totalPages}
          </span>
          <button
            className="pagination-button"
            disabled={currentPage === totalPages}
            onClick={() => setCurrentPage((prev) => prev + 1)}
          >
            Next
          </button>
        </div>
      </div>
      {showModal && (
        <div
          className="modal show fade"
          tabIndex={-1}
          style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
        >
          <div
            className="modal-dialog modal-dialog-centered"
            style={{ border: "none" }}
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Modal Title</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleClose}
                ></button>
              </div>
              <div className="modal-body">
                <p>This is the modal content.</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleClose}
                >
                  Close
                </button>
                <button type="button" className="btn btn-primary">
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AccessManagementTable;
